


/*! BLOG */


.blog {
padding-top:1em;
padding-bottom:3em;
}
@media (max-width: 767px) {
.blog {
padding-top:1em;
padding-bottom:3em;
}
}
@media (max-width: 576px) {
.blog {
padding-top:1em;
padding-bottom:1em;
}
}

.blog_row {
margin-top:1em;
}
@media (max-width: 992px) {
.blog_row {
margin-top:0.5em;
}
}

.blog_filter {
margin-bottom:0.25em;
}

.blog_filter ul {
display:inline-block;
list-style-type: none;
padding: 0;
margin: 0;
}

.blog_filter li {
display:inline-block;
padding: 0;
margin: 0;
padding-top:0.5em;
padding-bottom:0.5em;
margin-left:0.75em;
margin-right:0.75em;
margin-bottom:0.5em;
cursor:pointer;
font-weight:700;
line-height:110%;
}

.blog_filter li:hover {
color:$color-blog-filter-hover;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.blog_filter li.active {
color:$color-blog-filter-active;
border-bottom: 2px solid $color-blog-filter-active;
}

.blog_item {
padding-top:2em;
padding-bottom:1em;
}
@media (max-width: 767px) {
.blog_item {
padding-top:1.5em;
}
}

.blog_photo {
margin-bottom:0.75em;
width:100%;
padding-top: 70%;
position: relative; 
}
@media (max-width: 767px) {
.blog_photo {
padding-top: 60%;
}
}

.blog_subtitle {
font-weight:700;
font-size:0.9em;
line-height:115%;
letter-spacing:0.02em;
margin-bottom:0.25em;
color:$color-blog-subtitle;
padding-right:0.5em;
}

.blog_title,
.blog_title h1,
.blog_title h2,
.blog_title h3,
.blog_title h4,
.blog_title h5,
.blog_title h6 {
color:$primary !important;
line-height:120%;
margin-bottom:0.25em;
padding-right:0.5em;
}
@media (max-width: 992px) {
.blog_title,
.blog_title h1,
.blog_title h2,
.blog_title h3,
.blog_title h4,
.blog_title h5,
.blog_title h6 {
padding-right:0.25em;
}
}

.blog_description {
padding-right:1em;
}
@media (max-width: 992px) {
.blog_description {
padding-right:0.5em;
}
}

.blog_button {
margin-top:2.5em;
}
@media (max-width: 576px) {
.blog_button {
margin-top:2em;
}
}


