


/*! BLANK PAGE */


.blank_text {
padding-top:3em;
padding-bottom:2em;
}
@media (max-width: 576px) {
.blank_text {
padding-top:2em;
padding-bottom:2em;
}
}

