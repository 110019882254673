

/*! Mobile Navigation */



.topnavigation_mobile {
padding-left:1.25em;
padding-right:1.25em;
padding-top:0.875em;
padding-bottom:0.25em;
position:relative;
top:0;
z-index:100;
margin-bottom:1em;
display:none;
position:relative;
z-index:1000;
}
@media (max-width: 992px) {
.topnavigation_mobile {
display:block;
}
}

.topnavigation_mobile_menu {
text-align:right;
margin-top:0.5em;
}
@media (max-width: 480px) {
.topnavigation_mobile_menu {
margin-top:0.5em;
}
}

.topnavigation_mobile_menu_active {
opacity:0.9;
}

.topnavigation_mobile_menu img {
width:2em;
top:4em;
margin-top:0.5em;
cursor:pointer;
}

.topnavigation_mobile_menu_show {
display:none;
position:relative;
right:0;
background-color:$color-mobilenavigation-background;
color:$color-mobilenavigation-text;
font-weight:700;
margin-top: 1em;
padding-left:0em;
padding-right:0em;
padding-top:0.75em;
padding-bottom:0.75em;
box-shadow: -4px 4px 12px 0px rgba(0, 0, 0, 0.3);
z-index:1000;
text-align:right;
}


.topnavigation_mobile_menu_show ul {
display:inline-block;
list-style-type: none;
padding: 0;
margin: 0;
}

.topnavigation_mobile_menu_show li {
display:block;
padding: 0;
margin: 0;
line-height:110%;
}

.topnavigation_mobile_menu_show li a {
color:$color-mobilenavigation-text;
display:block;
padding-left:2em;
padding-right:2em;
padding-top:0.875em;
padding-bottom:0.875em;
}

.topnavigation_mobile_menu_show li.active a {
text-decoration: underline;
}

.topnavigation_mobile_menu_show li a:hover {
color:$color-mobilenavigation-text;
background:$color-mobilenavigation-hover;
}



