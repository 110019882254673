

/*! Body Margins */

.body-margins {
width:100%;
max-width:1366px;
margin:0;
margin-left:auto;
margin-right:auto;
}



/*! Body Padding */

.padding-body {
padding-left:9em;
padding-right:9em;
}
@media (max-width: 1300px) {
.padding-body {
padding-left:8em;
padding-right:8em;
}
}
@media (max-width: 1200px) {
.padding-body {
padding-left:7em;
padding-right:7em;
}
}
@media (max-width: 1100px) {
.padding-body {
padding-left:5em;
padding-right:5em;
}
}
@media (max-width: 992px) {
.padding-body {
padding-left:3em;
padding-right:3em;
}
}
@media (max-width: 900px) {
.padding-body {
padding-left:2em;
padding-right:2em;
}
}
@media (max-width: 767px) {
.padding-body {
padding-left:2em;
padding-right:2em;
}
}
@media (max-width: 576px) {
.padding-body {
padding-left:2em;
padding-right:2em;
}
}

.padding-body-leftonly {
padding-left:9em;
}
@media (max-width: 1300px) {
.padding-body-leftonly {
padding-left:8em;
}
}
@media (max-width: 1200px) {
.padding-body-leftonly {
padding-left:7em;
}
}
@media (max-width: 1100px) {
.padding-body-leftonly {
padding-left:5em;
}
}
@media (max-width: 992px) {
.padding-body-leftonly {
padding-left:3em;
}
}
@media (max-width: 900px) {
.padding-body-leftonly {
padding-left:2em;
}
}
@media (max-width: 767px) {
.padding-body-leftonly {
padding-left:2em;
}
}
@media (max-width: 576px) {
.padding-body-leftonly {
padding-left:2em;
padding-right:2em;
}
}


