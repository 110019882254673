

/*! Top Navigation */


.topnavigation {
padding-left:4em;
padding-right:4em;
padding-top:1.5em;
padding-bottom:0.5em;
position:relative;
top:0;
z-index:100;
margin-bottom:1em;
}
@media (max-width: 1300px) {
.topnavigation {
padding-left:2em;
padding-right:2em;
font-size:0.95em;
}
}
@media (max-width: 1100px) {
.topnavigation {
padding-left:1em;
padding-right:0.5em;
font-size:0.9em;
}
}
@media (max-width: 992px) {
.topnavigation {
display:none;
}
}

.topnavigation_text {
padding: 0;
margin: 0;
padding-left:2em;
padding-right:2em;
}
@media (max-width: 1100px) {
.topnavigation_text {
padding-left:1em;
padding-right:1em;
}
}

.topnavigation_center {
display: flex;
justify-content: center;
align-items: center; 
}

.topnavigation_logo {
text-align:left;
padding: 0;
margin: 0;
padding-right:2em;
padding-top:1em;
}
@media (max-width: 992px) {
.topnavigation_logo {
padding-left:1em;
}
}
@media (max-width: 767px) {
.topnavigation_logo {
padding-left:0.75em;
}
}

.topnavigation_logo img {
margin: 0;
width:auto;
height:3.75em;
}
@media (max-width: 1200px) {
.topnavigation_logo img {
height:3em;
}
}
@media (max-width: 767px) {
.topnavigation_logo img {
height:2.75em;
}
}
@media (max-width: 576px) {
.topnavigation_logo img {
height:2.5em;
}
}
@media (max-width: 480px) {
.topnavigation_logo img {
height:1.75em;
}
}

.topnavigation_links {
text-align:center;
padding: 0;
margin: 0;
font-weight:700;
}

.topnavigation_contact {
text-align:right;
padding: 0;
margin: 0;
padding-left:2em;
}

.topnavigation ul {
display:inline-block;
list-style-type: none;
padding: 0;
margin: 0;
}

.topnavigation li {
display:inline-block;
padding: 0;
margin: 0;
padding-left:1em;
padding-right:1em;
}
@media (max-width: 1200px) {
.topnavigation li {
padding-left:0.75em;
padding-right:0.75em;
}
}

.topnavigation li,
.topnavigation li a {
color:$color-topnavigation-link;
border-top:2px solid transparent;
border-bottom:2px solid transparent;
}

.topnavigation li.active a {
color:$color-topnavigation-link-active;
border-bottom:2px solid $primary;
}

.topnavigation li a:hover {
color:$color-topnavigation-link-hover;
border-bottom:2px solid $primary;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.banner_container_withphoto .topnavigation li,
.banner_container_withphoto .topnavigation li a {
color:$color-topnavigation-link-white;
border-top:2px solid transparent;
border-bottom:2px solid transparent;
}

.banner_container_withphoto .topnavigation li.active a {
color:$color-topnavigation-link-white-active;
border-bottom:2px solid $color-topnavigation-link-white-active;
}

.banner_container_withphoto .topnavigation li a:hover {
color:$color-topnavigation-link-white-hover;
border-bottom:2px solid $color-topnavigation-link-white-hover;
}


