


/*! Slick - Hide until ready, so it doesn't flash all the screens on load */

.carousel div {
visibility: hidden;
transition: opacity 1s ease;
-webkit-transition: opacity 1s ease;
}

.carousel.slick-initialized div {
visibility: visible;
}




/*! Dots */

.slick-dots {
margin-top:-12em;
z-index:200;
}

.slick-dots li {
position: relative;
display: inline-block;
height: 20px;
width: 20px;
margin: 0 5px;
padding: 0;
cursor: pointer;
}
@media (max-width: 767px) {
.slick-dots li {
margin: 0 8px;
}
}
@media (max-width: 576px) {
.slick-dots li {
margin: 0 7px;
}
}

.slick-dots li button:before {
font-size: 12px;
line-height: 12px;
color:$color-slideshow-dots;
opacity: 1;
}
@media (max-width: 767px) {
.slick-dots li button:before {
font-size: 14px;
line-height: 14px;
}
}
@media (max-width: 576px) {
.slick-dots li button:before {
font-size: 16px;
line-height: 16px;
}
}

.slick-dots li button:hover:before {
color: $black;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}


.slick-dots li.slick-active button:before {
color: $color-slideshow-dots-active;
opacity: 1;
}

.slick-dots {
position: absolute;
bottom: 5px;
list-style: none;
display: block;
text-align: center;
padding: 0;
margin: 0;
width: 100%;
}






/*! Arrows */

.slick-next:before, .slick-prev:before {
font-family: slick;
font-size: 48px;
line-height: 1;
color: $color-slideshow-arrows;
opacity: .75;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.slick-next:hover:before,
.slick-prev:hover:before {
color: $black;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.slick-next {
right: 20px;
}
@media (max-width: 640px) {
.slick-next {
right: 15px;
}
}

.slick-prev {
left: 20px;
}
@media (max-width: 640px) {
.slick-prev {
left: 15px;
}
}

.slick-next, .slick-prev {
position: absolute;
display: block;
height: auto !important;
width: 40px;
line-height: 0;
font-size: 0;
cursor: pointer;
background: 0 0;
color: transparent;
top: 42%;
transform: translate(0,-50%);
padding: 0;
border: none;
outline: 0;
z-index: 1000;
}
@media (max-width: 1200px) {
.slick-next, .slick-prev {
height: auto;
width: 36px;
top: 36%;
}
}
@media (max-width: 992px) {
.slick-next, .slick-prev {
height: auto;
width: 30px;
top: 34%;
}
}
@media (max-width: 900px) {
.slick-next, .slick-prev {
height: auto;
width: 27px;
top: 30%;
}
}
@media (max-width: 576px) {
.slick-next, .slick-prev {
height: auto;
width: 22px;
top: 24%;
}
}

.slick-next:before {
display:none;
}

.slick-prev:before {
display:none;
}

.slick-next img,
.slick-prev img {
opacity:1;
}

.slick-next img:hover,
.slick-prev img:hover {
opacity:0.9;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.slick-list.dragging {
cursor: -webkit-grab !important;
cursor: grab !important;
}

.slick-dotted.slick-slider {
margin-bottom: 0px;
}


