


/*! Limit Character Width */


.limitcharacterwidth {
max-width:50em;
margin-left:auto;
margin-right:auto;
}
@media (max-width: 767px) {
.limitcharacterwidth {
max-width:100%;
}
}


