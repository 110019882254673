

/*! CASE STUDIES */


.casestudies {
padding-top:1em;
padding-bottom:1em;
}

.casestudies_stars img {
width:50%;
margin-bottom:1.75em;
}
@media (max-width: 767px) {
.casestudies_stars img {
width:40%;
margin-bottom:1em;
}
}
@media (max-width: 576px) {
.casestudies_stars img {
width:50%;
margin-bottom:1em;
}
}

.casestudies_button {
margin-top:1.5em;
}

.casestudies_item {
margin-bottom:3em;
}
@media (max-width: 1200px) {
.casestudies_item {
margin-bottom:2.5em;
}
}
@media (max-width: 992px) {
.casestudies_item {
margin-bottom:2em;
}
}
@media (max-width: 767px) {
.casestudies_item {
margin-bottom:1em;
}
}

.casestudies_item .blog_title {
padding-right:1em;
}
@media (max-width: 576px) {
.casestudies_item .blog_title {
padding-right:0em;
}
}

