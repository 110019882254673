

/*! POPUPS */




/*! Popup - Text Only */

.white-popup {
position: relative;
background: $color-popup-background;
width: auto;
max-width: 70%;
margin-top: 2em;
margin-bottom: 2em;
margin-left: auto;
margin-right: auto;
padding-top:3em;
padding-bottom:2em;
padding-left:1.5em;
padding-right:1.5em;
}
@media screen and (max-width: 1100px) {
.white-popup {
max-width: 75%;
}
}
@media screen and (max-width: 900px) {
.white-popup {
max-width: 80%;
}
}
@media screen and (max-width: 800px) {
.white-popup {
max-width: 85%;
}
}
@media screen and (max-width: 640px) {
.white-popup {
margin-top: 8em;
max-width: 90%;
}
}

.white-popup img {
width:100%;
height:auto;
}





/*! Video iFrame */

.mfp-iframe-holder .mfp-content {
width:80%;
max-width: 1920px; 
}
@media (max-width: 1100px) {
.mfp-iframe-holder .mfp-content {
width:85%;
}
}
@media (max-width: 992px) {
.mfp-iframe-holder .mfp-content {
width:90%;
}
}
@media (max-width: 767px) {
.mfp-iframe-holder .mfp-content {
width:95%;
}
}
@media (max-width: 576px) {
.mfp-iframe-holder .mfp-content {
width:100%;
}
}




/*! Close Button */

.mfp-close {
margin:0;
}

.mfp-close-btn-in .mfp-close {
color: $color-popup-close !important;
background-color:transparent !important;
}

.mfp-close-btn-in .mfp-close:hover {
color: $color-popup-hover !important;
background-color:transparent !important;
}

.mfp-close:active {
top: 0; 
}

.mfp-iframe-holder .mfp-close {
top: -39px;
}

.mfp-close {
opacity:0.9;
}

.popupclose {
cursor:pointer;
}






/*! Arrows */

@media (max-width: 767px) {
.mfp-arrow-right:after {
margin-left: 64px;
}
.mfp-arrow-right:before {
margin-left: 64px;
}
.mfp-arrow-left:after {
margin-left: 4px;
}
.mfp-arrow-left:before {
margin-left: 4px;
}
}

.mfp-arrow-right::before {
border-left: 0;
}

.mfp-arrow-left::before {
border-left: 0;
border-right: 0;
}






/* Fade In */


.mfp-fade.mfp-bg {
opacity: 0;
-webkit-transition: all 0.25s ease-out;
-moz-transition: all 0.25s ease-out;
transition: all 0.25s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
opacity: 0;
-webkit-transition: all 0.25s ease-out;
-moz-transition: all 0.25s ease-out;
transition: all 0.25s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
opacity: 0;
}







/* z-index */

.mfp-bg {
z-index: 10042;
}

.mfp-wrap {
z-index: 10043;
}






