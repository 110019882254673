


/*! COMPANY */


.company_title_container {
margin-top:2em;
padding-top:2em;
padding-bottom:2em;
}

.company_title {
color:$primary;
font-weight:700;
font-size:2.25em;
text-transform:uppercase;
line-height:120%;
padding-right:1em;
}
@media (max-width: 1100px) {
.company_title {
font-size:2em;
}
}
@media (max-width: 992px) {
.company_title {
font-size:1.5em;
}
}
@media (max-width: 767px) {
.company_title {
font-size:1.25em;
}
}
@media (max-width: 576px) {
.company_title {
font-size:1.5em;
}
}

.company_icons {
text-align:right;
font-size:1em;
padding-top:0.75em;
}
@media (max-width: 992px) {
.company_icons {
font-size:1em;
}
}
@media (max-width: 576px) {
.company_icons {
font-size:0.5em;
}
}

.company_description {
margin-top:1em;
}

.company {
margin-bottom:2em;
}

.company_left {
padding:0;
position:relative;
margin-bottom:1em;
}

.company_right {
padding:0;
padding-left:1em;
position:relative;
margin-bottom:1em;
}
@media (max-width: 767px) {
.company_right {
padding-left:0;
}
}

.company_bigheight {
min-height:20em;
}
@media (max-width: 767px) {
.company_bigheight {
min-height:15em;
}
}

.company_imagefill {
padding:0;
position:absolute;
top:0;
bottom:0;
left:0;
right:0;
}

.company_map {
padding:0;
position:absolute;
top:0;
bottom:0;
left:1em;
right:0;
}
@media (max-width: 767px) {
.company_map {
left:0;
}
}

.company_text {
font-size:1.1em;
padding-top:0.5em;
padding-left:0.5em;
}
@media (max-width: 767px) {
.company_text {
font-size:1em;
text-align:center;
padding-left:0;
}
}

@media (max-width: 767px) {
.company_bottomtext {
text-align:left;
}
}

.company_buttons {
font-size:0.9em;
margin-top:1.5em;
}
@media (max-width: 767px) {
.company_buttons {
text-align:center;
}
}

.company_buttons button {
margin:0;
margin-bottom:1em;
padding-top:0.6em;
padding-bottom:0.6em;
padding-left:0.75em;
padding-right:0.75em;
margin-left:0.25em;
margin-right:0.25em;
}

.company_thumbs {
min-height:10em;
position:relative;
}
@media (max-width: 767px) {
.company_thumbs {
min-height:15em;
}
}

.company_bottomtext_container {
padding:0;
margin:0;
border-top: 2px solid $color-grey-75;
padding-top:1em;
}

.company_bottomtext_container .company_text {
font-size:1em;
}

.company_bottomtext_container h6 {
font-size:1.5em;
}
@media (max-width: 992px) {
.company_bottomtext_container h6 {
font-size:1.25em;
}
}

.company_map_2 {
height:20em;
}

.company_bigheight_2 {
height:34em;
}
@media (max-width: 767px) {
.company_bigheight_2 {
height:20em;
}
}

.company_text_2 {
padding-top:22em;
position:relative;
}

.gm-style .gm-style-iw-d {
padding-right: 1em !important;
padding-bottom: 0.5em !important;
}

