


/*! Alert */

.alert {
font-size:0.8em !important;
text-transform:uppercase;
line-height:110%;
font-weight:700;
letter-spacing:0.015em;
background-color:$color-alert-red;
color:$color-alert-text;
text-align:center;
border-radius:0;
margin:0;
padding:0;
padding-top:0.5em !important;
padding-bottom:0.5em !important;
padding-left:2em;
padding-right:2em;
z-index:999;
}
@media (max-width: 1200px) {
.alert {
font-size:0.85em;
}
}

.alert a {
color:$color-alert-link;
text-decoration: underline;
}

.alert a:hover {
opacity:0.8;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.alert p {
margin:0;
display:inline;
}


