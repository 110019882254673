


/*! ADVERTISE */



.advertising_title h4 {
margin-bottom:0.25em;
}

.advertising_price {
font-size:1.25em;
color:$color-grey-20;
margin-bottom:1.25em;
}

.advertising_description {
margin-top:1em;
font-size:1em;
}

.advertising_description button {
margin-top:0.5em;
font-size:1.125em;
}

.advertising_description li {
margin-bottom:0.5em;
}

.advertising_description li ul {
padding:0;
margin-left:0em;
margin-top:0.5em;
margin-bottom:0.75em;
list-style: none;
}

.advertising_description li ul li::before {
padding:0;
margin:0;
content: "- "; 
padding-right:0.5em;
}

.advertising_text_left {
padding-right:1em;
}
@media (max-width: 767px) {
.advertising_text_left {
padding-right:0;
}
}

.advertisingsection {
margin-top:1em;
margin-bottom:2em;
}

.advertising_image {
border:1px solid #666;
}

.advertising_image {
width:90%;
}



