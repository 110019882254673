

/*! PRICING */



.pricing {
padding-top:2em;
padding-bottom:3em;
}
@media (max-width: 1200px) {
.pricing {
padding-top:2em;
padding-bottom:2em;
}
}
@media (max-width: 1100px) {
.pricing {
padding-top:1em;
padding-bottom:1em;
}
}
@media (max-width: 992px) {
.pricing {
padding-top:0em;
padding-bottom:1em;
}
}

.pricing_column_border {
border:1px solid $black;
}

.pricing_column {
padding-top:2.125em;
padding-bottom:1.5em;
padding-left:2em;
padding-right:2em;
}
@media (max-width: 1200px) {
.pricing_column {
padding-left:1.5em;
padding-right:1.5em;
}
}
@media (max-width: 992px) {
.pricing_column {
padding-left:1em;
padding-right:1em;
}
}
@media (max-width: 767px) {
.pricing_column {
padding-top:1.5em;
padding-left:2em;
padding-right:2em;
}
}

.pricing_chart {
margin-top:2em;
}

.pricing_column ul {
list-style: none;
list-style-type: none!important;
margin-top:2em;
padding-left: 1.5em;
}

.pricing_column ul li:before {
content: '✓'!important;
margin-right: 0.25em;
margin-left: -1em;
text-indent: 1.5em;
font-size: 1.5em!important;
line-height:100%;
color:$color-pricing-text;
}

.pricing_column li {
color:$color-pricing-text;
line-height:120%;
}

.pricing_title h1,
.pricing_title h2,
.pricing_title h3,
.pricing_title h4,
.pricing_title h5,
.pricing_title h6,
.pricing_title {
color:$color-pricing-title;
}
@media (max-width: 767px) {
.pricing_title {
font-size:1.25em;
}
}

.background-color-bluehover .pricing_title h1,
.background-color-bluehover .pricing_title h2,
.background-color-bluehover .pricing_title h3,
.background-color-bluehover .pricing_title h4,
.background-color-bluehover .pricing_title h5,
.background-color-bluehover .pricing_title h6,
.background-color-bluehover .pricing_title {
color:$color-pricing-title;
}

.pricing_description {
color:$color-pricing-text;
margin-bottom:1.5em;
}

.pricing_price {
color:$color-pricing-text;
font-weight:700;
margin-bottom:1em;
}

.pricing_price_enterprise {
color:$color-pricing-text;
font-weight:700;
margin-bottom:1em;
}

.price_big {
font-size:2.25em;
}

.price_small {
font-size:1em;
}

.pricing_subtitle {
color:$color-pricing-text;
font-weight:700;
}

.pricing_button {
margin-top:2em;
}

.pricing_column_left {
margin-top:3em;
border-radius:1.5em 0 0 0;
}
@media (max-width: 767px) {
.pricing_column_left {
margin-top:2em;
border-radius:1.5em 1.5em 0 0;
}
}

.pricing_column_center {
margin-top:1em;
padding:0;
position:relative;
box-shadow: 2px 6px 12px 10px rgba(0, 0, 0, 0.3);
border-radius:1.5em 1.5em 0 0;
}
@media (max-width: 767px) {
.pricing_column_center {
margin-top:2em;
box-shadow: none;
border-radius:1.5em 1.5em 0 0;
}
}

.pricing_tab {
background-color:$primary_light;
background-image: linear-gradient($primary_light, $primary_medium);
border-radius:1.5em 1.5em 0 0;
border-bottom:1px solid $black;
padding-top:0.5em;
padding-bottom:0.5em;
padding-left:0.5em;
padding-right:0.5em;
text-align:center;
}

.pricing_tab_text {
font-size:1.25em;
letter-spacing:0.025em;
text-transform:uppercase;
font-weight:700;
color:$white;
text-shadow: 2px 2px $primary;
}

.pricing_column_right {
margin-top:3em;
border-radius:0 1.5em 0 0;
}
@media (max-width: 767px) {
.pricing_column_right {
margin-top:2em;
border-radius:1.5em 1.5em 0 0;
}
}

.pricing_ribbon {
margin-top:0em;
position:absolute;
top:-1em;
left:0em;
margin-left:-1em;
margin-right:-1em;
z-index:100;
}

.pricing_column_text {
position:relative;
z-index:200;
padding-top:0em;
}
@media (max-width: 767px) {
.pricing_column_text {
padding-top:6em;
}
}
@media (max-width: 576px) {
.pricing_column_text {
padding-top:4em;
}
}



