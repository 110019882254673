



/*! Footer - Bottom */


.footer_bottom {
font-size:1em;
background-color:$color-background-darkgrey;
padding-top:1em;
padding-bottom:1em;
}

.footer_bottom_left {
padding:0;
padding-right:1em;
padding-top:1em;
padding-bottom:1em;
}
@media (max-width: 767px) {
.footer_bottom_left {
padding-left:0;
padding-right:0;
text-align:left;
}
}

.footer_bottom_right {
padding:0;
}
@media (max-width: 767px) {
.footer_bottom_right {
padding-left:1em;
padding-right:1em;
margin-bottom:2em;
text-align:center;
}
}

.footer_bottom_logo img {
width:7em;
margin-bottom:1em;
}
@media (max-width: 1200px) {
.footer_bottom_logo img {
width:6em;
}
}
@media (max-width: 576px) {
.footer_bottom_logo img {
width:5em;
}
}

.footer_bottom_text {
color:#000;
padding-top:1em;
padding-bottom:0;
padding-left:1em;
padding-right:1em;
}
@media (max-width: 1600px) {
.footer_bottom_text {
padding-left:0.5em;
}
}
@media (max-width: 1400px) {
.footer_bottom_text {
padding-left:1em;
}
}
@media (max-width: 767px) {
.footer_bottom_text {
padding-right:3em;
}
}
@media (max-width: 576px) {
.footer_bottom_text {
padding-right:0em;
}
}

.footer_bottom_text_text {
max-width:27em;
padding-right:2em;
}
@media (max-width: 992px) {
.footer_bottom_text_text {
padding-right:1em;
}
}
@media (max-width: 767px) {
.footer_bottom_text_text {
max-width:100%;
padding-right:0;
}
}

.footer_bottom_links {
font-size:1.1em;
line-height:115%;
margin-top:2em;
}
@media (max-width: 1200px) {
.footer_bottom_links {
margin-top:1.5em;
}
}
@media (max-width: 992px) {
.footer_bottom_links {
margin-top:1.5em;
}
}

.footer_bottom_links ul {
list-style-type: none;
margin:0;
padding:0;
}

.footer_bottom_links li {
margin:0;
padding:0;
display:block;
margin-bottom:1.25em;
padding-right:0.5em;
}
@media (max-width: 1200px) {
.footer_bottom_links li {
margin-bottom:1em;
}
}
@media (max-width: 767px) {
.footer_bottom_links li {
padding-left:0.5em;
padding-right:0.5em;
}
}

.footer_bottom_links a {
text-decoration: underline;
color:$color-footer-bottom-links;
}

.footer_bottom_links a:hover {
color:$color-footer-bottom-links-hover;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.footer_bottom_social {
margin-top:1.75em;
}

.footer_bottom_social img {
cursor:pointer;
width:1.25em;
height:1.25em;
margin-right:1.5em;
margin-bottom:1em;
opacity:0.5;
}
@media (max-width: 992px) {
.footer_bottom_social img {
margin-right:1em;
}
}
@media (max-width: 900px) {
.footer_bottom_social img {
margin-right:0.75em;
}
}
@media (max-width: 767px) {
.footer_bottom_social img {
margin-right:2em;
}
}
@media (max-width: 576px) {
.footer_bottom_social img {
margin-right:1.5em;
opacity:0.675;
}
}
@media (max-width: 480px) {
.footer_bottom_social img {
margin-right:1.25em;
}
}

.footer_bottom_social img:hover {
opacity:1;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}






/*! Footer - Copyright */


.footer {
background:$color-footer-background;
padding-top:2em;
padding-bottom:2em;
font-size:0.85em;
margin-top:1em;
}
@media (max-width: 576px) {
.footer {
padding-top:2em;
padding-bottom:2em;
}
}

.footer,
.footer a {
color:$color-footer-text;
font-weight:700;
}

.footer .active a {
color:$color-topnavigation-link-active;
border-bottom:2px solid $color-topnavigation-link-active;
}

.footer a:hover {
color:$color-footer-hover;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.footer_left {
text-align:left;
}
@media (max-width: 576px) {
.footer_left {
text-align:center;
}
}

.footer_right {
text-align:right;
}
@media (max-width: 576px) {
.footer_right {
text-align:center;
margin-bottom:1em;
}
}

.footer_legal_right {
margin-left:4em;
}
@media (max-width: 992px) {
.footer_legal_right {
margin-left:3em;
}
}
@media (max-width: 767px) {
.footer_legal_right {
margin-left:2.5em;
}
}
@media (max-width: 576px) {
.footer_legal_right {
margin-left:2em;
}
}

.footer_legal_left a.active,
.footer_legal_right a.active {
text-decoration: underline;
}

