

/*! bootstrap responsive points */

@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 767px) {
}
@media (max-width: 576px) {
}





/*! Change default width of bootstrap containers */

.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl,
.container {
max-width: 100%;
}





/*! Breakpoints */

.breakpoints {
width:100%;
text-align:center;
min-height:6em;
margin-top:8em;
margin-bottom:8em;
padding:2em;
background-color:grey;
}
@media (max-width: 3000px) {
.breakpoints {
background-color:blue;
}
}
@media (max-width: 2800px) {
.breakpoints {
background-color:green;
}
}
@media (max-width: 2600px) {
.breakpoints {
background-color:yellow;
}
}
@media (max-width: 2400px) {
.breakpoints {
background-color:orange;
}
}
@media (max-width: 2200px) {
.breakpoints {
background-color:red;
}
}
@media (max-width: 2000px) {
.breakpoints {
background-color:purple;
}
}
@media (max-width: 1800px) {
.breakpoints {
background-color:blue;
}
}
@media (max-width: 1600px) {
.breakpoints {
background-color:green;
}
}
@media (max-width: 1400px) {
.breakpoints {
background-color:yellow;
}
}
@media (max-width: 1300px) {
.breakpoints {
background-color:orange;
}
}
@media (max-width: 1200px) {
.breakpoints {
background-color:red;
}
}
@media (max-width: 1100px) {
.breakpoints {
background-color:purple;
}
}
@media (max-width: 992px) {
.breakpoints {
background-color:blue;
}
}
@media (max-width: 900px) {
.breakpoints {
background-color:red;
}
}
@media (max-width: 767px) {
.breakpoints {
background-color:grey;
}
}
@media (max-width: 640px) {
.breakpoints {
background-color:yellow;
}
}
@media (max-width: 576px) {
.breakpoints {
background-color:orange;
}
}
@media (max-width: 480px) {
.breakpoints {
background-color:red;
}
}




