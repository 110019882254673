// SCSS VARIABLES




// Fonts
$font-body: 'Noto Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-headlines: 'Noto Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;


// Font Weights
$fontweight-body:400;
$fontweight-headline:700;


// Greys
$color-grey-98: #fafafa;
$color-grey-97: #f7f7f7;
$color-grey-95: #efefef;
$color-grey-93: #ededed;
$color-grey-92: #ebebeb;
$color-grey-90: #e2e2e2;
$color-grey-85: #d9d9d9;
$color-grey-80: #cfcfcf;
$color-grey-75: #bfbfbf;
$color-grey-65: #a6a6a6;
$color-grey-60: #999999;
$color-grey-55: #8c8c8c;
$color-grey-50: #808080;
$color-grey-45: #737373;
$color-grey-42: #6a6a6a;
$color-grey-40: #666666;
$color-grey-35: #595959;
$color-grey-33: #545454;
$color-grey-30: #4d4d4d;
$color-grey-28: #474747;
$color-grey-25: #404040;
$color-grey-20: #333333;
$color-grey-15: #262626;
$color-grey-10: #1a1a1a;
$color-grey-5: #0d0d0d;


$color-grey-88: #e0e0e0;
$color-grey-35: #595959;
$color-grey-22: #393939;
$color-grey-17: #2c2c2c;

$black: #000;
$white: #fff;





// Theme Colors
$fleetassist-logo-green: #b0ff4e;
$fleetassist-logo-blue: #668ff7;
$fleetassist-logo-darkblue: #2a6ebf;


$old-fleetassist-yellow: #ffdf1c;
$old-fleetassist-yellow_hover: #ffeb6b;
$old-fleetassist-yellow_light: #faefa5;
$old-fleetassist-darkyellow: #706623;
$old-fleetassist-darkyellow_hover: #877b29;

$breakdownnow-yellow: #ffff00;
$breakdownnow-red: #aa353e;

$primary: $breakdownnow-red;
$primary_hover: lighten( $breakdownnow-red, 10% );
$primary_light: lighten( $breakdownnow-red, 44% );
$primary_medium: lighten( $breakdownnow-red, 25% );
$secondary: $breakdownnow-red;
$secondary_hover: lighten( $breakdownnow-red, 10% );






// Theme Colors
$color-darkestgrey: $color-grey-25;
$color-darkergrey: $color-grey-28;
$color-darkgrey: $color-grey-33;
$color-bodytextgrey: $color-grey-30;
$color-lightgrey: $color-grey-75;
$color-lightergrey: $color-grey-85;
$color-lightestgrey: $color-grey-93;




// Body Text
$color-bodytext: $color-bodytextgrey;
$color-pagebackground: $white;

$color-headline: $color-darkestgrey;
$color-bold: $black;





// Links
$color-link: $primary;
$color-link-hover: $primary_hover;





// Backgrounds
$color-background-lightgrey: $color-lightestgrey;
$color-background-lightgrey-text: $color-darkergrey;
$color-background-darkgrey: $color-lightergrey;
$color-background-darkgrey-text: $color-darkestgrey;






// Buttons
$color-button: $primary;
$color-button-text: $white;

$color-button-hover: $primary_hover;
$color-button-text-hover: $white;

$color-buttonwhite: $white;
$color-buttonwhite-text: $color-darkgrey;

$color-buttonwhite-hover: $color-darkgrey;
$color-buttonwhite-text-hover: $white;

$color-buttonwhiteandblack: $white;
$color-buttonwhiteandblack-text: $black;

$color-buttonwhiteandblack-hover: $black;
$color-buttonwhiteandblack-text-hover: $white;

$color-buttonprimary: $primary;
$color-buttonprimary-text: $white;

$color-buttonprimary-hover: $primary_hover;
$color-buttonprimary-text-hover: $white;






// Forms
$color-input-text: $black !important;
$color-input-border: $black;
$color-input-background: $white;
$color-input-background-hover: $color-grey-98;
$color-input-placeholder: $black !important;
$input-placeholder-color: $black !important;


// Popups
$color-popup-background: $white;
$color-popup-close: $black;
$color-popup-hover: $color-grey-20;



// Slick Slideshow
$color-slideshow-dots: $color-grey-60;
$color-slideshow-dots-active: $color-grey-20;
$color-slideshow-arrows: $white;



// Alerts
$color-alert-primary: $primary;
$color-alert-red: #ff0000;
$color-alert-text: $white;
$color-alert-link: $white;



// Top Navigation
$color-topnavigation-link: $black;
$color-topnavigation-link-active: $color-darkestgrey;
$color-topnavigation-link-hover: $color-darkestgrey;
$color-topnavigation-link-white: $white;
$color-topnavigation-link-white-active: $color-lightestgrey;
$color-topnavigation-link-white-hover: $color-lightestgrey;


// Mobile Navigation
$color-mobilenavigation-background: $color-darkestgrey;
$color-mobilenavigation-text: $white;
$color-mobilenavigation-hover: $color-darkgrey;


// Banner
$color-banner-background: $color-lightestgrey;
$color-banner-title: $color-darkestgrey;
$color-banner-description: $color-darkestgrey;
$color-banner-title-white: $white;
$color-banner-description-white: $white;


// Pricing
$color-pricing-title: $color-grey-20;
$color-pricing-title-darker: $color-grey-15;
$color-pricing-text: $color-darkestgrey;


// FAQ
$color-faq-question-text: $color-darkestgrey;
$color-faq-question-text-hover: $black;
$color-faq-answer-background: $primary_light;



// Blog
$color-blog-subtitle: $color-darkestgrey;
$color-blog-filter-hover: $black;
$color-blog-filter-active: $black;



// Footer
$color-footer-bottom-links: $color-darkestgrey;
$color-footer-bottom-links-hover: $black;
$color-footer-background: $color-lightestgrey;
$color-footer-text: $color-darkestgrey;
$color-footer-hover: $black;





