

/*! FORMS */


select,
input {
background-color:$white;
-webkit-appearance: none;
padding-top:0.6em;
padding-bottom:0.6em;
padding-left:0.5em;
padding-right:0.5em;
border:2px solid $color-input-border;
color:$color-input-text;
margin-bottom:1em;
}

select:hover,
input:hover {
background-color:$color-input-background-hover;
}

select:focus,
input:focus {
background-color:$color-input-background;
}


.form-floating>.form-control::-webkit-input-placeholder
.form-floating>.form-control::-moz-placeholder
.form-floating>.form-control::placeholder
.form-floating>.form-control:not(:-moz-placeholder-shown)
.form-floating>.form-control:focus,.form-floating>.form-control:not(:placeholder-shown)
.form-floating>.form-control:-webkit-autofill
.form-floating>.form-select
.form-floating>.form-control:not(:-moz-placeholder-shown)~label
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label,
.form-control::-webkit-input-placeholder,
.form-control::-moz-placeholder,
.form-control::placeholder,
.form-control:placeholder,
.form-control::placeholder,
.form-control::-webkit-input-placeholder,
.form-control:-moz-placeholder,
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder,
.form-control:placeholder-shown,
input:placeholder,
input::placeholder,
input::-webkit-input-placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
input::-ms-input-placeholder,
input:placeholder-shown,
:placeholder,
::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder,
:placeholder-shown {
color: $black !important;
opacity: 1;
}

.join-title {
font-size:1.1em;
}

.join-popup b,
.join-popup strong {
font-size:1.1em;
}

.join-popup input {
padding-right:2em;
width:100% !important;
margin-top:0.5em;
margin-bottom:1em;
}

.join-popup button {
margin-top:1em;
}

.join_bottomtext {
margin-top:1em;
}

.join_left {
padding-right:2em;
}
@media (max-width: 767px) {
.join_left {
padding-right:0.8em;
margin-bottom:2em;
}
}

.join_right {
border-left: 1px solid #666;
padding-left:2em;
}
@media (max-width: 767px) {
.join_right {
border-left: 0;
padding-left:0.8em;
}
}

.join_right ul {
margin-top:1em;
}

.join_right li {
margin-bottom:1em;
}





