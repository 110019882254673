

/*! Photo Ratios */

.photoratio_absolute {
position:absolute;
top:0;
bottom:0;
left:0;
right:0;
}

.photoratio_25 {
width:100%;
padding-top: 33%;
position: relative; 
}

.photoratio_33 {
width:100%;
padding-top: 33%;
position: relative; 
}

.photoratio_35 {
width:100%;
padding-top: 35%;
position: relative; 
}

.photoratio_40 {
width:100%;
padding-top: 40%;
position: relative; 
}

.photoratio_50 {
width:100%;
padding-top: 50%;
position: relative; 
}

.photoratio_169 {
width:100%;
padding-top: 56.25%;
position: relative; 
}

.photoratio_66 {
width:100%;
padding-top: 66%;
position: relative; 
}

.photoratio_70 {
width:100%;
padding-top: 70%;
position: relative; 
}

.photoratio_43 {
width:100%;
padding-top: 75%;
position: relative; 
}

.photoratio_80 {
width:100%;
padding-top: 80%;
position: relative; 
}

.photoratio_85 {
width:100%;
padding-top: 85%;
position: relative; 
}

.photoratio_90 {
width:100%;
padding-top: 90%;
position: relative; 
}

.photoratio_square {
width:100%;
padding-top: 100%;
position: relative; 
}

.photoratio_110 {
width:100%;
padding-top: 105%;
position: relative; 
}