

/*! FAQ */



.faq_text {
margin-top:4em;
margin-bottom:6em;
font-weight:700;
}
@media (max-width: 992px) {
.faq_text {
margin-top:3em;
margin-bottom:5em;
}
}
@media (max-width: 576px) {
.faq_text {
margin-top:2em;
margin-bottom:4em;
}
}

@media (max-width: 992px) {
.faq_icon {
--bs-gutter-x: 1em;
}
}

.faq_icon img {
width:3em;
margin-right:1em;
margin-top:-0.5em;
}
@media (max-width: 992px) {
.faq_icon img {
margin-right:0em;
margin-top:-0.5em;
}
}
@media (max-width: 767px) {
.faq_icon img {
width:2em;
}
}

.faq_question {
padding-top:1.5em;
padding-bottom:0.625em;
padding-left:0.25em;
border-bottom: 2px solid $color-faq-question-text;
color:$color-faq-question-text;
cursor:pointer;
}
@media (max-width: 767px) {
.faq_question {
padding-top:1em;
}
}

.faq_question:hover {
color:$color-faq-question-text-hover;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.faq_answer {
background-color:$color-faq-answer-background;
padding-top:1em;
padding-bottom:0.5em;
padding-left:0.25em;
padding-right:1em;
display:none;
color:$black;
border-bottom: 2px solid $color-faq-question-text;
}
@media (max-width: 767px) {
.faq_answer {
padding-top: .5em;
padding-bottom: 0;
}
}

.faq_answer_text {
padding-top:0.4em;
}

.faq_answer a {
text-decoration: underline;
}

.faq_text {
padding-top:0.0125em;
}


