

/*! Mobile Only */

.hideonmobile {
display:block;
}
@media screen and (max-width: 767px) {
.hideonmobile {
display:none;
}
}

.showonmobile {
display:none;
}
@media screen and (max-width: 767px) {
.showonmobile {
display:block;
}
}

.hideonmedium {
display:block;
}
@media screen and (max-width: 992px) {
.hideonmedium {
display:none;
}
}

.showonmedium {
display:none;
}
@media screen and (max-width: 992px) {
.showonmedium {
display:block;
}
}


