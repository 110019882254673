


/*! LISTING */

.advertise_filters {
background-color:$color-background-lightgrey;
color:$black;
padding-top:2em;
padding-bottom:2em;
}

.advertise_filters_padding {
padding-left:4.5em;
padding-right:4.5em;
}
@media (max-width: 1300px) {
.advertise_filters_padding {
padding-left:2.5em;
padding-right:2.5em;
}
}
@media (max-width: 1100px) {
.advertise_filters_padding {
padding-left:1.5em;
padding-right:1.5em;
}
}
@media (max-width: 992px) {
.advertise_filters_padding {
padding-left:0.5em;
padding-right:0.5em;
}
}
@media (max-width: 992px) {
.advertise_filters_padding {
padding-left:0.5em;
padding-right:0.5em;
}
}

.advertise_filters a {
color:$black !important;
}

.advertise_filters a:hover {
color:$primary !important;
}

.advertise_filters_span {
padding-right:2em;
}

.advertise_listing {
background-color:$color-background-lightgrey;
padding-top:2em;
padding-bottom:1em;
}

.advertise_listing_row {
}

.advertise_listing_item {
margin-bottom:2em;
box-shadow: 0.5em 0.5em 1em rgba(0, 0, 0, 0.25);
}

.advertise_listing_photo {
min-height:10em;
position:relative;
}

.advertise_listing_photo_half {
min-height:10em;
}

.advertise_listing_photo_fill {
position:absolute;
top:0;
bottom:0;
left:0;
right:0;
}

.advertise_listing_text {
background-color:$white;
padding-top:2em;
padding-bottom:2em;
padding-left:2em;
padding-right:2em;
position:relative;
}
@media (max-width: 992px) {
.advertise_listing_text {
padding-top:1.5em;
padding-bottom:1.5em;
padding-left:1.5em;
padding-right:1.5em;
}
}
@media (max-width: 767px) {
.advertise_listing_text {
padding-top:2em;
padding-bottom:2em;
padding-left:1em;
padding-right:1em;
}
}
@media (max-width: 576px) {
.advertise_listing_text {
padding-top:2em;
padding-bottom:2em;
padding-left:2em;
padding-right:2em;
}
}

.advertise_listing_text_half {
padding-top:4em;
padding-bottom:4em;
padding-left:1.25em;
padding-right:1.25em;
}
@media (max-width: 992px) {
.advertise_listing_text_half {
padding-top:3em;
padding-bottom:3em;
padding-left:1em;
padding-right:1em;
}
}
@media (max-width: 767px) {
.advertise_listing_text_half {
padding-top:2em;
padding-bottom:2em;
padding-left:1em;
padding-right:1em;
}
}
@media (max-width: 576px) {
.advertise_listing_text_half {
padding-top:2em;
padding-bottom:2em;
padding-left:2em;
padding-right:2em;
}
}

.advertise_listing_icons {
margin-bottom:2em;
font-size:0.85em;
}

.listing_icon {
background-color:$color-grey-25;
color:$white;
border-radius:6em;
line-height:100%;
padding-top:0.4em;
padding-bottom:0.4em;
padding-left:0.6em;
padding-right:0.6em;
margin-right:0.25em;
margin-bottom:1em;
}

.advertise_listing_title {
text-transform:uppercase;
margin-bottom:1em;
font-size:1.5em;
font-weight:700;
line-height:120%;
}
@media (max-width: 1100px) {
.advertise_listing_title {
font-size:1.25em;
}
}
@media (max-width: 992px) {
.advertise_listing_title {
font-size:1.125em;
}
}
@media (max-width: 767px) {
.advertise_listing_title {
margin-bottom:0.5em;
font-size:1.125em;
}
}
@media (max-width: 576px) {
.advertise_listing_title {
font-size:1.25em;
}
}

.advertise_listing_description {
margin-bottom:1em;
padding-bottom:6em;
color:$color-grey-15;
font-size:1.1em;
}
@media (max-width: 992px) {
.advertise_listing_description {
font-size:1em;
padding-bottom:5em;
}
}

.advertise_listing_description_half {
margin-bottom:0em;
padding-bottom:0em;
}

.advertise_listing_button {
margin-top:4em;
position:absolute;
bottom:2em;
}

@media (max-width: 767px) {
.advertise_listing_item_full {
padding-left:1em;
padding-right:1em;
}
}

.advertise_listing_item_full_bottom {
background-color:$white;
padding-top:2em;
padding-bottom:2em;
padding-left:2em;
padding-right:2em;
}
@media (max-width: 767px) {
.advertise_listing_item_full_bottom {
padding-left:1em;
padding-right:1em;
text-align:center;
}
}

.advertise_listing_item_left {
padding-right:2em;
}
@media (max-width: 767px) {
.advertise_listing_item_left {
padding-left:1em;
padding-right:1em;
}
}

.advertise_listing_item_right {
padding-left:2em;
}
@media (max-width: 767px) {
.advertise_listing_item_right {
padding-left:1em;
padding-right:1em;
}
}

.advertise_listing_bottom {
background-color:$white;
padding-top:1em;
padding-bottom:1em;
padding-left:1em;
padding-right:1em;
}

.advertise_listing_bottom_title {
margin-bottom:1.5em;
}

.advertise_listing_bottom_columns {
font-weight:700;
text-transform:uppercase;
}

.advertise_listing_bottom_columns ul {
list-style: none;
margin:0;
padding:0;
}

.advertise_listing_bottom_columns li {
margin:0;
padding:0;
margin-bottom:1.5em;
line-height:115%;
}

.advertise_listing_bottom_col {
padding-right:1em;
}
@media (max-width: 767px) {
.advertise_listing_bottom_col {
padding-right:0em;
}
}










/*! FILTER POPUP */


.filter-popup {
font-size:1.125em;
font-weight:bold;
}
@media (max-width: 992px) {
.filter-popup {
font-size:1.1em;
}
}
@media (max-width: 767px) {
.filter-popup {
font-size:1em;
}
}

.filter-popup h6 {
font-size:1.5em;
}

.filter-heading {
margin-bottom:1.25em;
}

.filter-title {
font-size:1.1em;
margin-bottom:0.75em;
color:$black;
}

.filter-checks {
margin-bottom:1.5em;
}

.filter-popup input {
margin-bottom:0;
}

/* Customize the label (the filtercontainer) */
.filtercontainer {
display: block;
position: relative;
padding-left: 2.5em;
padding-top: 0.5em;
margin-bottom: 1em;
cursor: pointer;
font-size: 1em;
line-height:120%;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

/* Hide the browser's default checkbox */
.filtercontainer input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

/* Create a custom checkbox */
.checkmark {
position: absolute;
top: 0;
left: 0;
height: 2em;
width: 2em;
background-color: #fff;
border:3px solid #666;
}

/* On mouse-over, add a grey background color */
.filtercontainer:hover input ~ .checkmark {
background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.filtercontainer input:checked ~ .checkmark {
background-color: $primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.filtercontainer input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.filtercontainer .checkmark:after {
left: 0.6em;
top: 0.25em;
width: 0.5em;
height: 1em;
border: solid white;
border-width: 0 5px 5px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}



