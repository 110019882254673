/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "@circlon/angular-tree-component/css/angular-tree-component.css";

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-labels .mat-tab-label {
    opacity: 1;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-group.mat-primary .mat-tab-list .mat-ink-bar {
    background-color: #f75651;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-labels .mat-tab-label:hover {
    background-color: #91beff;
    border-radius: 6px 6px 0 0;
    color: #000;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-labels .mat-tab-label.mat-tab-label-active {
    background-color: #0170bf;
    color: #fff;
    border-radius: 6px 6px 0 0;
}

.cursor {
    cursor: pointer;
}
.bgDialogHeader {
    background-color: #aa353e !important;
    color: #ffffff !important;
    padding: 3px;
    font-size: 18px !important;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}
.bgBlue {
    background: #0170bf !important;
    font-weight: 500 !important;
    color: #fff !important;
    font-size: 14px !important;
    outline: none !important;
}

.mat-mdc-dialog-container .boxclose {
    cursor: pointer;
    color: #fff !important;
    font-weight: bold;
    font-size: 32px;
    padding-right: 10px;
    display: inline-block;
    line-height: 0px;
    text-decoration: none;
    font-size: 32px !important;
    padding-right: 10px !important;
}

.boxclose:before {
    content: "×";
}

.select2 {
    width: 100% !important;
}

.table-header {
    border: 1px solid #0170bf;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #aaa;
    -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}

.mat-mdc-form-field {
    display: block !important;
}

.mat-mdc-dialog-container {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    border-radius: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-label {
    color: black !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #f44336 !important;
}

.table-header {
    overflow: hidden;
    margin: 10px;
}

label {
    color: black;
}

.logo {
    width: 230px;
}

/* login signup page css */
.login-signup {
    width: 535px;
    background: #eee;
    margin: 50px auto;
    height: 100%;
}

.login-signup.alert-msg {
    margin-top: 70px;
}

.login-signup form {
    padding: 40px 50px 40px;
}

.login-signup li {
    list-style: none;
    margin-bottom: 10px;
}

.login-signup input[type="submit"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 20px;
}

.login-signup .btm-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a {
        color: #007bff !important;
        cursor: pointer;
        text-decoration: underline;
    }
}

.login-signup .btm-form:after {
    display: none;
}

.login-signup .btm-form:before {
    display: none;
}

.login-signup.signup {
    margin-top: 15px;
}

.login-header {
    background: #215493;
    text-align: center;
    color: #fff;
    padding: 8px 10px;
}

.login-header h2 {
    margin: 0;
    font-size: 25px;
}

.alert-info {
    color: #055160 !important;
    background-color: #cff4fc !important;
    border-color: #b6effb;
    height: 30px;
}

.mat-mdc-snack-bar-action {
    color: #ffffff;
}

.sucess-snackbar {
    background-color: #00d68f;
    color: #ffffff;
}

.error-snackbar {
    background-color: #ff3d71;
    color: #ffffff;
}

.warning-snackbar {
    background-color: #ffaa00;
    color: #ffffff;
}

.info-snackbar {
    background-color: #0095ff;
    color: #ffffff;
}
.cursor-pointer {
    cursor: pointer;
}
a {
    cursor: pointer;
}
.common-btn {
    background-color: rgb(170, 53, 62) !important;
    color: #ffffff !important;
    font-weight: bold !important;
}
.common-txt-clr {
    color: rgb(170, 53, 62);
}
tree-loading-component {
    display: none;
}

.mat-mdc-dialog-container {
    padding: 15px !important;
    overflow: hidden !important;
}

.cdk-global-scrollblock {
    overflow-y: auto !important;
}

.cdk-global-overlay-wrapper {
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}
