


/*! Banner */

.yellowtext {
color:$primary;
}

.banner_container {
position:relative;
z-index:0;
}

.banner_container_withphoto {
background-color:black;
}

.banner_photo {
position:absolute;
top:0;
bottom:0;
left:0;
right:0;
opacity:0.5;
z-index:0;
}

.banner_photo_lighter {
opacity:0.65;
}

.banner {
position:relative;
min-height:10em;
z-index:100;
}

.banner_inside {
margin-top:2em;
margin-bottom:3em;
}
@media (max-width: 767px) {
.banner_inside {
margin-bottom:2em;
}
}

.banner_text {
padding-top:6em;
padding-bottom:4em;
text-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.2);
}
@media (max-width: 992px) {
.banner_text {
padding-top:4em;
}
}
@media (max-width: 576px) {
.banner_text {
padding-top:3em;
padding-bottom:1em;
}
}

.banner_text_home {
padding-top:8em;
padding-bottom:6em;
text-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.2);
}
@media (max-width: 992px) {
.banner_text {
padding-top:6em;
}
}
@media (max-width: 576px) {
.banner_text {
padding-top:3em;
padding-bottom:1em;
}
}

.banner_text_inside {
padding-top:12em;
padding-bottom:12em;
}
@media (max-width: 1400px) {
.banner_text_inside {
padding-top:11em;
padding-bottom:11em;
}
}
@media (max-width: 1200px) {
.banner_text_inside {
padding-top:10em;
padding-bottom:10em;
}
}
@media (max-width: 1100px) {
.banner_text_inside {
padding-top:9em;
padding-bottom:9em;
}
}
@media (max-width: 992px) {
.banner_text_inside {
padding-top:8em;
padding-bottom:8em;
}
}
@media (max-width: 767px) {
.banner_text_inside {
padding-top:5em;
padding-bottom:5em;
}
}
@media (max-width: 576px) {
.banner_text_inside {
padding-top:3em;
padding-bottom:1em;
}
}

.banner_text_inside_small {
padding-top:8em;
padding-bottom:8em;
}
@media (max-width: 1400px) {
.banner_text_inside_small {
padding-top:7em;
padding-bottom:7em;
}
}
@media (max-width: 1200px) {
.banner_text_inside_small {
padding-top:6em;
padding-bottom:6em;
}
}
@media (max-width: 1100px) {
.banner_text_inside_small {
padding-top:5em;
padding-bottom:5em;
}
}
@media (max-width: 992px) {
.banner_text_inside_small {
padding-top:4em;
padding-bottom:4em;
}
}
@media (max-width: 767px) {
.banner_text_inside_small {
padding-top:3em;
padding-bottom:3em;
}
}
@media (max-width: 576px) {
.banner_text_inside_small {
padding-top:3em;
padding-bottom:1em;
}
}

.banner_title {
color:$color-banner-title;
margin-bottom:1.5em;
}

.banner h1,
.banner h2,
.banner h3,
.banner h4,
.banner h5,
.banner h6 {
line-height:120%;
color:$color-banner-title;
margin:0;
}

.banner_container_withphoto .banner_title,
.banner_container_withphoto .banner h1,
.banner_container_withphoto .banner h2,
.banner_container_withphoto .banner h3,
.banner_container_withphoto .banner h4,
.banner_container_withphoto .banner h5,
.banner_container_withphoto .banner h6 {
color:$color-banner-title-white;
}

.homebanner h1 {
font-size:3.5em;
}
@media (max-width: 992px) {
.homebanner h1 {
font-size:3.25em;
}
}
@media (max-width: 900px) {
.homebanner h1 {
font-size:3em;
}
}
@media (max-width: 767px) {
.homebanner h1 {
font-size:2.5em;
}
}
@media (max-width: 576px) {
.homebanner h1 {
font-size:2em;
}
}

.banner_description {
color:$color-banner-description;
font-size:1.125em;
font-weight:700;
margin:0;
}

.banner_button {
margin-top:2em;
}

.banner_container_withphoto .banner_description {
color:$color-banner-description-white;
}

.banner_form {
margin-top:3em;
display: flex;
justify-content: center;
align-items: center;
}

.banner_form_button {
margin-top:.125em;
}

.banner_form input {
margin-left:0.25em;
margin-right:0.25em;
}

.banner_form_bottom {
font-size:0.75em;
margin-top:0.25em;
}

.banner_bottom_photo {
width:100%;
margin-top:1em;
margin-bottom:2em;
}

.banner_bottom_photo img {
width:100%;
}

.banner_photo_right {
text-align:center;
}
@media (max-width: 576px) {
.banner_photo_right {
margin-top:2em;
}
}

.banner_photo_verticalphone img {
width:auto;
height:24em;
}

.banner_button {
margin-top:2em;
}

.banner_text_center {
display: flex;
justify-content: center;
align-items: center;
}

.banner_pricing_bottom {
margin-top:1.5em;
}

