


/*! Print */

@media print {
* {
-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
color-adjust: exact !important;                 /* Firefox*/
}
html,body {
width:1200px;
font-size: 100%;
color:$black;
}
html {
margin: 0;
}
@page {                
size: 1200px;
}
div {
break-inside: avoid;
page-break-inside: avoid;
}
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
clear: both;
}
div[class^="col-"], div[class*=" col-"] {
float: left;
}
}






/*! Site Specific Print Styles */

@media print {
.pricing_column {
max-width:33%;
margin-left:0;
margin-right:0;
}
.faq_answer {
display:block !important;
}
.ttn_mission_photo_container {
display:none;
}
.features_slideshow_text {
position: relative;
display:block;
width:75%;
margin:0;
padding-left:1em;
padding-right:1em;
}
.blog_item,
.isotope_item {
position: relative;
display:block;
max-width:33%;
padding-left:1em;
padding-right:1em;
}
.home_why_item {
position: relative;
display:block;
max-width:50%;
padding-left:1em;
padding-right:1em;
}
}


