@charset "UTF-8";
/*!
 * Bootstrap v5.0.0-beta3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue:#0d6efd;
  --bs-indigo:#6610f2;
  --bs-purple:#6f42c1;
  --bs-pink:#d63384;
  --bs-red:#dc3545;
  --bs-orange:#fd7e14;
  --bs-yellow:#ffc107;
  --bs-green:#198754;
  --bs-teal:#20c997;
  --bs-cyan:#0dcaf0;
  --bs-white:#fff;
  --bs-gray:#6c757d;
  --bs-gray-dark:#343a40;
  --bs-primary:#0d6efd;
  --bs-secondary:#6c757d;
  --bs-success:#198754;
  --bs-info:#0dcaf0;
  --bs-warning:#ffc107;
  --bs-danger:#dc3545;
  --bs-light:#f8f9fa;
  --bs-dark:#212529;
  --bs-font-sans-serif:system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*, ::after, ::before {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25; }

hr:not([size]) {
  height: 1px; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2; }

.h1, h1 {
  font-size: calc(1.375rem + 1.5vw); }

@media (min-width: 1200px) {
  .h1, h1 {
    font-size: 2.5rem; } }

.h2, h2 {
  font-size: calc(1.325rem + .9vw); }

@media (min-width: 1200px) {
  .h2, h2 {
    font-size: 2rem; } }

.h3, h3 {
  font-size: calc(1.3rem + .6vw); }

@media (min-width: 1200px) {
  .h3, h3 {
    font-size: 1.75rem; } }

.h4, h4 {
  font-size: calc(1.275rem + .3vw); }

@media (min-width: 1200px) {
  .h4, h4 {
    font-size: 1.5rem; } }

.h5, h5 {
  font-size: 1.25rem; }

.h6, h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[data-bs-original-title], abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol, ul {
  padding-left: 2rem; }

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b, strong {
  font-weight: bolder; }

.small, small {
  font-size: .875em; }

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3; }

sub, sup {
  position: relative;
  font-size: .75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }

a:hover {
  color: #0a58ca; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

code, kbd, pre, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: .875em; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

code {
  font-size: .875em;
  color: #d63384;
  word-wrap: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: .2rem .4rem;
  font-size: .875em;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem; }

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img, svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, select {
  text-transform: none; }

[role=button] {
  cursor: pointer; }

select {
  word-wrap: normal; }

select:disabled {
  opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit; }

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem; } }

legend + * {
  clear: left; }

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: .5rem; }

.initialism {
  font-size: .875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote > :last-child {
  margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
  color: #6c757d; }

.blockquote-footer::before {
  content: "— "; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: .5rem;
  line-height: 1; }

.figure-caption {
  font-size: .875em;
  color: #6c757d; }

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x:1.5rem;
  --bs-gutter-y:0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2); }

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

.g-0, .gx-0 {
  --bs-gutter-x:0; }

.g-0, .gy-0 {
  --bs-gutter-y:0; }

.g-1, .gx-1 {
  --bs-gutter-x:0.25rem; }

.g-1, .gy-1 {
  --bs-gutter-y:0.25rem; }

.g-2, .gx-2 {
  --bs-gutter-x:0.5rem; }

.g-2, .gy-2 {
  --bs-gutter-y:0.5rem; }

.g-3, .gx-3 {
  --bs-gutter-x:1rem; }

.g-3, .gy-3 {
  --bs-gutter-y:1rem; }

.g-4, .gx-4 {
  --bs-gutter-x:1.5rem; }

.g-4, .gy-4 {
  --bs-gutter-y:1.5rem; }

.g-5, .gx-5 {
  --bs-gutter-x:3rem; }

.g-5, .gy-5 {
  --bs-gutter-y:3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; }
  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x:0; }
  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y:0; }
  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x:0.25rem; }
  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y:0.25rem; }
  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x:0.5rem; }
  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y:0.5rem; }
  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x:1rem; }
  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y:1rem; }
  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x:1.5rem; }
  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y:1.5rem; }
  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x:3rem; }
  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y:3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; }
  .g-md-0, .gx-md-0 {
    --bs-gutter-x:0; }
  .g-md-0, .gy-md-0 {
    --bs-gutter-y:0; }
  .g-md-1, .gx-md-1 {
    --bs-gutter-x:0.25rem; }
  .g-md-1, .gy-md-1 {
    --bs-gutter-y:0.25rem; }
  .g-md-2, .gx-md-2 {
    --bs-gutter-x:0.5rem; }
  .g-md-2, .gy-md-2 {
    --bs-gutter-y:0.5rem; }
  .g-md-3, .gx-md-3 {
    --bs-gutter-x:1rem; }
  .g-md-3, .gy-md-3 {
    --bs-gutter-y:1rem; }
  .g-md-4, .gx-md-4 {
    --bs-gutter-x:1.5rem; }
  .g-md-4, .gy-md-4 {
    --bs-gutter-y:1.5rem; }
  .g-md-5, .gx-md-5 {
    --bs-gutter-x:3rem; }
  .g-md-5, .gy-md-5 {
    --bs-gutter-y:3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; }
  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x:0; }
  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y:0; }
  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x:0.25rem; }
  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y:0.25rem; }
  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x:0.5rem; }
  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y:0.5rem; }
  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x:1rem; }
  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y:1rem; }
  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x:1.5rem; }
  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y:1.5rem; }
  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x:3rem; }
  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y:3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; }
  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x:0; }
  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y:0; }
  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x:0.25rem; }
  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y:0.25rem; }
  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x:0.5rem; }
  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y:0.5rem; }
  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x:1rem; }
  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y:1rem; }
  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x:1.5rem; }
  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y:1.5rem; }
  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x:3rem; }
  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y:3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.3333333333%; }
  .offset-xxl-2 {
    margin-left: 16.6666666667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.3333333333%; }
  .offset-xxl-5 {
    margin-left: 41.6666666667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.3333333333%; }
  .offset-xxl-8 {
    margin-left: 66.6666666667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.3333333333%; }
  .offset-xxl-11 {
    margin-left: 91.6666666667%; }
  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x:0; }
  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y:0; }
  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x:0.25rem; }
  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y:0.25rem; }
  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x:0.5rem; }
  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y:0.5rem; }
  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x:1rem; }
  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y:1rem; }
  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x:1.5rem; }
  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y:1.5rem; }
  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x:3rem; }
  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y:3rem; } }

.table {
  --bs-table-bg:transparent;
  --bs-table-striped-color:#212529;
  --bs-table-striped-bg:rgba(0, 0, 0, 0.05);
  --bs-table-active-color:#212529;
  --bs-table-active-bg:rgba(0, 0, 0, 0.1);
  --bs-table-hover-color:#212529;
  --bs-table-hover-bg:rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6; }

.table > :not(caption) > * > * {
  padding: .5rem .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }

.table > tbody {
  vertical-align: inherit; }

.table > thead {
  vertical-align: bottom; }

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: .25rem .25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg:var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg:var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg:var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg:#cfe2ff;
  --bs-table-striped-bg:#c5d7f2;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#bacbe6;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#bfd1ec;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #bacbe6; }

.table-secondary {
  --bs-table-bg:#e2e3e5;
  --bs-table-striped-bg:#d7d8da;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#cbccce;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#d1d2d4;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #cbccce; }

.table-success {
  --bs-table-bg:#d1e7dd;
  --bs-table-striped-bg:#c7dbd2;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#bcd0c7;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#c1d6cc;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #bcd0c7; }

.table-info {
  --bs-table-bg:#cff4fc;
  --bs-table-striped-bg:#c5e8ef;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#badce3;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#bfe2e9;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #badce3; }

.table-warning {
  --bs-table-bg:#fff3cd;
  --bs-table-striped-bg:#f2e7c3;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#e6dbb9;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#ece1be;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #e6dbb9; }

.table-danger {
  --bs-table-bg:#f8d7da;
  --bs-table-striped-bg:#eccccf;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#dfc2c4;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#e5c7ca;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #dfc2c4; }

.table-light {
  --bs-table-bg:#f8f9fa;
  --bs-table-striped-bg:#ecedee;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#dfe0e1;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#e5e6e7;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #dfe0e1; }

.table-dark {
  --bs-table-bg:#212529;
  --bs-table-striped-bg:#2c3034;
  --bs-table-striped-color:#fff;
  --bs-table-active-bg:#373b3e;
  --bs-table-active-color:#fff;
  --bs-table-hover-bg:#323539;
  --bs-table-hover-color:#fff;
  color: #fff;
  border-color: #373b3e; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: .5rem; }

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem; }

.form-text {
  margin-top: .25rem;
  font-size: .875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control[type=file] {
  overflow: hidden; }

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-control::-webkit-date-and-time-value {
  height: 1.5em; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

.form-control::file-selector-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  -webkit-margin-end: .75rem;
  margin-inline-end: .75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3; }

.form-control::-webkit-file-upload-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  -webkit-margin-end: .75rem;
  margin-inline-end: .75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem; }

.form-control-sm::file-selector-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  -webkit-margin-end: .5rem;
  margin-inline-end: .5rem; }

.form-control-sm::-webkit-file-upload-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  -webkit-margin-end: .5rem;
  margin-inline-end: .5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem; }

.form-control-lg::file-selector-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem; }

.form-control-lg::-webkit-file-upload-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: .375rem; }

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: .25rem; }

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: .25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: .75rem;
  background-image: none; }

.form-select:disabled {
  background-color: #e9ecef; }

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem; }

.form-select-lg {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: .125rem; }

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact; }

.form-check-input[type=checkbox] {
  border-radius: .25em; }

.form-check-input[type=radio] {
  border-radius: 50%; }

.form-check-input:active {
  filter: brightness(90%); }

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd; }

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5; }

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  opacity: .5; }

.form-switch {
  padding-left: 2.5em; }

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none; } }

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.btn-check:disabled + .btn, .btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.form-range:focus {
  outline: 0; }

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-range::-moz-focus-outer {
  border: 0; }

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none; } }

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe; }

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -moz-appearance: none;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none; } }

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe; }

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.form-range:disabled {
  pointer-events: none; }

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd; }

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd; }

.form-floating {
  position: relative; }

.form-floating > .form-control, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem .75rem; }

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem .75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity .1s ease-in-out,transform .1s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none; } }

.form-floating > .form-control::-moz-placeholder {
  color: transparent; }

.form-floating > .form-control::placeholder {
  color: transparent; }

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem; }

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem; }

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem; }

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem; }

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: .65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  opacity: .65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }

.input-group > .form-control, .input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0; }

.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: 3; }

.input-group .btn {
  position: relative;
  z-index: 2; }

.input-group .btn:focus {
  z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem; }

.input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem; }

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: .25rem; }

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block; }

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem); }

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: #198754;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center,center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem); }

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.form-check-input.is-valid, .was-validated .form-check-input:valid {
  border-color: #198754; }

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: #198754; }

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: .25rem; }

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block; }

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem); }

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #dc3545;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center,center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem); }

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #dc3545; }

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: #dc3545; }

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.input-group .form-control.is-invalid, .input-group .form-select.is-invalid, .was-validated .input-group .form-control:invalid, .was-validated .input-group .form-select:invalid {
  z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover {
  color: #212529; }

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: .65; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca; }

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }

.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be; }

.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64; }

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }

.btn-check:active + .btn-secondary, .btn-check:checked + .btn-secondary, .btn-secondary.active, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e; }

.btn-check:active + .btn-secondary:focus, .btn-check:checked + .btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43; }

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }

.btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f; }

.btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2; }

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }

.btn-check:active + .btn-info, .btn-check:checked + .btn-info, .btn-info.active, .btn-info:active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2; }

.btn-check:active + .btn-info:focus, .btn-check:checked + .btn-info:focus, .btn-info.active:focus, .btn-info:active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }

.btn-info.disabled, .btn-info:disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720; }

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }

.btn-check:active + .btn-warning, .btn-check:checked + .btn-warning, .btn-warning.active, .btn-warning:active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720; }

.btn-check:active + .btn-warning:focus, .btn-check:checked + .btn-warning:focus, .btn-warning.active:focus, .btn-warning:active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }

.btn-warning.disabled, .btn-warning:disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37; }

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }

.btn-check:active + .btn-danger, .btn-check:checked + .btn-danger, .btn-danger.active, .btn-danger:active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834; }

.btn-check:active + .btn-danger:focus, .btn-check:checked + .btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb; }

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }

.btn-check:active + .btn-light, .btn-check:checked + .btn-light, .btn-light.active, .btn-light:active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb; }

.btn-check:active + .btn-light:focus, .btn-check:checked + .btn-light:focus, .btn-light.active:focus, .btn-light:active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }

.btn-light.disabled, .btn-light:disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21; }

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }

.btn-check:active + .btn-dark, .btn-check:checked + .btn-dark, .btn-dark.active, .btn-dark:active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f; }

.btn-check:active + .btn-dark:focus, .btn-check:checked + .btn-dark:focus, .btn-dark.active:focus, .btn-dark:active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }

.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.btn-check:active + .btn-outline-primary:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }

.btn-check:active + .btn-outline-secondary, .btn-check:checked + .btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-check:active + .btn-outline-secondary:focus, .btn-check:checked + .btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }

.btn-check:active + .btn-outline-success, .btn-check:checked + .btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-check:active + .btn-outline-success:focus, .btn-check:checked + .btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #198754;
  background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }

.btn-check:active + .btn-outline-info, .btn-check:checked + .btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-check:active + .btn-outline-info:focus, .btn-check:checked + .btn-outline-info:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus, .btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }

.btn-check:active + .btn-outline-warning, .btn-check:checked + .btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-check:active + .btn-outline-warning:focus, .btn-check:checked + .btn-outline-warning:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus, .btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }

.btn-check:active + .btn-outline-danger, .btn-check:checked + .btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-check:active + .btn-outline-danger:focus, .btn-check:checked + .btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }

.btn-check:active + .btn-outline-light, .btn-check:checked + .btn-outline-light, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show, .btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-check:active + .btn-outline-light:focus, .btn-check:checked + .btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus, .btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }

.btn-check:active + .btn-outline-dark, .btn-check:checked + .btn-outline-dark, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show, .btn-outline-dark:active {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-check:active + .btn-outline-dark:focus, .btn-check:checked + .btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus, .btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline; }

.btn-link:hover {
  color: #0a58ca; }

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d; }

.btn-group-lg > .btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem; }

.btn-group-sm > .btn, .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem; }

.fade {
  transition: opacity .15s linear; }

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none; } }

.fade:not(.show) {
  opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }

.dropdown, .dropend, .dropstart, .dropup {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: .125rem; }

.dropdown-menu-start {
  --bs-position:start; }

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0; }

.dropdown-menu-end {
  --bs-position:end; }

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position:start; }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-end {
    --bs-position:end; }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position:start; }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-md-end {
    --bs-position:end; }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position:start; }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-end {
    --bs-position:end; }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position:start; }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-end {
    --bs-position:end; }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position:start; }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position:end; }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%; }

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: .125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto; }

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: .125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: #e9ecef; }

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd; }

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: .5rem 1rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: .25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6; }

.dropdown-menu-dark .dropdown-item:focus, .dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd; }

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd; }

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15); }

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6; }

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd; }

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  flex: 1 1 auto; }

.btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem; }

.dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
  margin-left: 0; }

.dropstart .dropdown-toggle-split::before {
  margin-right: 0; }

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem; }

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%; }

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px; }

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none; } }

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: .25rem; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill .nav-item, .nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item, .nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem; }

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between; }

.navbar-brand {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static; }

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none; } }

.navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 .25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row; }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-xxl .navbar-toggler {
    display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }

.navbar-expand .navbar-nav {
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem; }

.navbar-expand .navbar-nav-scroll {
  overflow: visible; }

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit; }

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: .5rem; }

.card-subtitle {
  margin-top: -.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }

.card-footer {
  padding: .5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }

.card-header-tabs {
  margin-right: -.5rem;
  margin-bottom: -.5rem;
  margin-left: -.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -.5rem;
  margin-left: -.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(.25rem - 1px); }

.card-img, .card-img-bottom, .card-img-top {
  width: 100%; }

.card-img, .card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-group > .card {
  margin-bottom: .75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease; }

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none; } }

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg); }

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none; } }

.accordion-button:hover {
  z-index: 2; }

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.accordion-item:first-of-type {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.accordion-item:last-of-type {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.accordion-flush .accordion-item:first-child {
  border-top: 0; }

.accordion-flush .accordion-item:last-child {
  border-bottom: 0; }

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/"); }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none; } }

.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: .375rem .75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem; }

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem; }

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem; }

.badge {
  display: inline-block;
  padding: .35em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem; }

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe; }

.alert-primary .alert-link {
  color: #06357a; }

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8; }

.alert-secondary .alert-link {
  color: #34383c; }

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc; }

.alert-success .alert-link {
  color: #0c4128; }

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }

.alert-info .alert-link {
  color: #04414d; }

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5; }

.alert-warning .alert-link {
  color: #523e02; }

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }

.alert-danger .alert-link {
  color: #6a1a21; }

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light .alert-link {
  color: #4f5050; }

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf; }

.alert-dark .alert-link {
  color: #101214; }

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width .6s ease; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: .5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.list-group-item + .list-group-item {
  border-top-width: 0; }

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0; }

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0; }

.list-group-horizontal > .list-group-item.active {
  margin-top: 0; }

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0; }

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }

.list-group-flush > .list-group-item {
  border-width: 0 0 1px; }

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff; }

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #084298;
  background-color: #bacbe6; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298; }

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5; }

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #41464b;
  background-color: #cbccce; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #0f5132;
  background-color: #bcd0c7; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #055160;
  background-color: #badce3; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #664d03;
  background-color: #e6dbb9; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #842029;
  background-color: #dfc2c4; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #636464;
  background-color: #e5e5e5; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #141619;
  background-color: #bebebf; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5; }

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75; }

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1; }

.btn-close.disabled, .btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: .25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: .875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.toast:not(.showing):not(.show) {
  opacity: 0; }

.toast.hide {
  display: none; }

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none; }

.toast-container > :not(:last-child) {
  margin-bottom: .75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: .5rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.toast-header .btn-close {
  margin-right: -.375rem;
  margin-left: .75rem; }

.toast-body {
  padding: .75rem;
  word-wrap: break-word; }

.modal-open {
  overflow: hidden; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px); }

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none; } }

.modal.show .modal-dialog {
  transform: none; }

.modal.modal-static .modal-dialog {
  transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden; }

.modal-dialog-scrollable .modal-body {
  overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: .5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.modal-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px); }

.modal-footer > * {
  margin: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0; }

.modal-fullscreen .modal-header {
  border-radius: 0; }

.modal-fullscreen .modal-body {
  overflow-y: auto; }

.modal-fullscreen .modal-footer {
  border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: .9; }

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem; }

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-auto[data-popper-placement^=top], .bs-tooltip-top {
  padding: .4rem 0; }

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: 0; }

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: .4rem .4rem 0;
  border-top-color: #000; }

.bs-tooltip-auto[data-popper-placement^=right], .bs-tooltip-end {
  padding: 0 .4rem; }

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000; }

.bs-tooltip-auto[data-popper-placement^=bottom], .bs-tooltip-bottom {
  padding: .4rem 0; }

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: 0; }

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000; }

.bs-tooltip-auto[data-popper-placement^=left], .bs-tooltip-start {
  padding: 0 .4rem; }

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem; }

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem; }

.popover .popover-arrow::after, .popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(-.5rem - 1px); }

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .bs-popover-top > .popover-arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff; }

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow, .bs-popover-end > .popover-arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem; }

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .bs-popover-end > .popover-arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff; }

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(-.5rem - 1px); }

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .bs-popover-bottom > .popover-arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff; }

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow, .bs-popover-start > .popover-arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem; }

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .bs-popover-start > .popover-arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff; }

.popover-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none; } }

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block; }

.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%); }

.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s; }

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
    transition: none; } }

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none; } }

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none; } }

.carousel-indicators .active {
  opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em; }

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1040;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none; } }

.offcanvas-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem; }

.offcanvas-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.offcanvas-backdrop::before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1039;
  width: 100vw;
  height: 100vh;
  content: "";
  background-color: rgba(0, 0, 0, 0.5); }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #0d6efd; }

.link-primary:focus, .link-primary:hover {
  color: #0a58ca; }

.link-secondary {
  color: #6c757d; }

.link-secondary:focus, .link-secondary:hover {
  color: #565e64; }

.link-success {
  color: #198754; }

.link-success:focus, .link-success:hover {
  color: #146c43; }

.link-info {
  color: #0dcaf0; }

.link-info:focus, .link-info:hover {
  color: #3dd5f3; }

.link-warning {
  color: #ffc107; }

.link-warning:focus, .link-warning:hover {
  color: #ffcd39; }

.link-danger {
  color: #dc3545; }

.link-danger:focus, .link-danger:hover {
  color: #b02a37; }

.link-light {
  color: #f8f9fa; }

.link-light:focus, .link-light:hover {
  color: #f9fafb; }

.link-dark {
  color: #212529; }

.link-dark:focus, .link-dark:hover {
  color: #1a1e21; }

.ratio {
  position: relative;
  width: 100%; }

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: ""; }

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio:100%; }

.ratio-4x3 {
  --bs-aspect-ratio:calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio:calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio:calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

.text-primary {
  color: #0d6efd !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #198754 !important; }

.text-info {
  color: #0dcaf0 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #212529 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.bg-primary {
  background-color: #0d6efd !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #198754 !important; }

.bg-info {
  background-color: #0dcaf0 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #212529 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*# sourceMappingURL=bootstrap.min.css.map */
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*! THEME SCSS */
/*! bootstrap responsive points */
/*! Change default width of bootstrap containers */
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl,
.container {
  max-width: 100%; }

/*! Breakpoints */
.breakpoints {
  width: 100%;
  text-align: center;
  min-height: 6em;
  margin-top: 8em;
  margin-bottom: 8em;
  padding: 2em;
  background-color: grey; }

@media (max-width: 3000px) {
  .breakpoints {
    background-color: blue; } }

@media (max-width: 2800px) {
  .breakpoints {
    background-color: green; } }

@media (max-width: 2600px) {
  .breakpoints {
    background-color: yellow; } }

@media (max-width: 2400px) {
  .breakpoints {
    background-color: orange; } }

@media (max-width: 2200px) {
  .breakpoints {
    background-color: red; } }

@media (max-width: 2000px) {
  .breakpoints {
    background-color: purple; } }

@media (max-width: 1800px) {
  .breakpoints {
    background-color: blue; } }

@media (max-width: 1600px) {
  .breakpoints {
    background-color: green; } }

@media (max-width: 1400px) {
  .breakpoints {
    background-color: yellow; } }

@media (max-width: 1300px) {
  .breakpoints {
    background-color: orange; } }

@media (max-width: 1200px) {
  .breakpoints {
    background-color: red; } }

@media (max-width: 1100px) {
  .breakpoints {
    background-color: purple; } }

@media (max-width: 992px) {
  .breakpoints {
    background-color: blue; } }

@media (max-width: 900px) {
  .breakpoints {
    background-color: red; } }

@media (max-width: 767px) {
  .breakpoints {
    background-color: grey; } }

@media (max-width: 640px) {
  .breakpoints {
    background-color: yellow; } }

@media (max-width: 576px) {
  .breakpoints {
    background-color: orange; } }

@media (max-width: 480px) {
  .breakpoints {
    background-color: red; } }

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }

/*! Body Font Sizes */
html, body {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  /*! font-size: 16px; 
line-height: 140%; */
  color: #4d4d4d;
  background-color: #fff;
  margin: 0;
  padding: 0; }

html, body {
  font-size: 1em; }

@media screen and (max-width: 20000px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 6000px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 5000px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 4000px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 3500px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 3000px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 2600px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 2400px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 2200px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 2000px) {
  html, body {
    font-size: 1em; } }

@media screen and (max-width: 1800px) {
  html, body {
    font-size: 1em; } }

/*! ACTUAL SIZE */
@media screen and (max-width: 1600px) {
  html, body {
    font-size: 1em; } }

/*! 110% - 1280w */
@media screen and (max-width: 1400px) {
  html, body {
    font-size: 1em; } }

/*! 125% */
@media screen and (max-width: 1200px) {
  html, body {
    font-size: 1em; } }

/*! 150% - 1024w */
@media screen and (max-width: 1100px) {
  html, body {
    font-size: 1em; } }

/*! 175% */
@media screen and (max-width: 900px) {
  html, body {
    font-size: 1em; } }

/*! 200% */
@media screen and (max-width: 800px) {
  html, body {
    font-size: 1em; } }

/*! MOBILE */
@media screen and (max-width: 640px) {
  html, body {
    font-size: 1em; } }

/*! HTML */
.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #404040;
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 2.5em;
  line-height: 110%;
  letter-spacing: -0.02em; }

.h1, h1 {
  font-size: 3em;
  line-height: 120%; }

@media (max-width: 1200px) {
  .h1, h1 {
    font-size: 2.75em; } }

@media (max-width: 992px) {
  .h1, h1 {
    font-size: 2.5em; } }

@media (max-width: 900px) {
  .h1, h1 {
    font-size: 2.25em; } }

@media (max-width: 767px) {
  .h1, h1 {
    font-size: 2em; } }

@media (max-width: 576px) {
  .h1, h1 {
    font-size: 2em; } }

.h2, h2 {
  font-size: 3em; }

@media (max-width: 1200px) {
  .h2, h2 {
    font-size: 2em; } }

@media (max-width: 900px) {
  .h2, h2 {
    font-size: 1.875em; } }

@media (max-width: 767px) {
  .h2, h2 {
    font-size: 1.75em; } }

.h3, h3 {
  font-size: 2.5em; }

@media (max-width: 1200px) {
  .h3, h3 {
    font-size: 2.25em; } }

@media (max-width: 900px) {
  .h3, h3 {
    font-size: 2em; } }

@media (max-width: 767px) {
  .h3, h3 {
    font-size: 1.75em; } }

.h4, h4 {
  font-size: 2em; }

@media (max-width: 1200px) {
  .h4, h4 {
    font-size: 1.75em; } }

@media (max-width: 900px) {
  .h4, h4 {
    font-size: 1.5em; } }

@media (max-width: 767px) {
  .h4, h4 {
    font-size: 1.5em; } }

.h5, h5,
.h6, h6 {
  font-size: 1.5em; }

@media (max-width: 1300px) {
  .h5, h5,
  .h6, h6 {
    font-size: 1.25em; } }

a {
  color: #aa353e;
  text-decoration: none; }

a:hover {
  color: #c74b55;
  text-decoration: none;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

dl, ol, ul {
  margin-bottom: 1em; }

ul li {
  margin-bottom: 0em; }

ol ol {
  list-style-type: lower-alpha; }

b, strong {
  font-weight: 700;
  color: #000; }

img {
  width: 100%; }

.clear {
  clear: both; }

@media screen and (max-width: 767px) {
  .clear_mobileonly {
    clear: both; } }

/*! Photo Ratios */
.photoratio_absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.photoratio_25 {
  width: 100%;
  padding-top: 33%;
  position: relative; }

.photoratio_33 {
  width: 100%;
  padding-top: 33%;
  position: relative; }

.photoratio_35 {
  width: 100%;
  padding-top: 35%;
  position: relative; }

.photoratio_40 {
  width: 100%;
  padding-top: 40%;
  position: relative; }

.photoratio_50 {
  width: 100%;
  padding-top: 50%;
  position: relative; }

.photoratio_169 {
  width: 100%;
  padding-top: 56.25%;
  position: relative; }

.photoratio_66 {
  width: 100%;
  padding-top: 66%;
  position: relative; }

.photoratio_70 {
  width: 100%;
  padding-top: 70%;
  position: relative; }

.photoratio_43 {
  width: 100%;
  padding-top: 75%;
  position: relative; }

.photoratio_80 {
  width: 100%;
  padding-top: 80%;
  position: relative; }

.photoratio_85 {
  width: 100%;
  padding-top: 85%;
  position: relative; }

.photoratio_90 {
  width: 100%;
  padding-top: 90%;
  position: relative; }

.photoratio_square {
  width: 100%;
  padding-top: 100%;
  position: relative; }

.photoratio_110 {
  width: 100%;
  padding-top: 105%;
  position: relative; }

/*! Body Margins */
.body-margins {
  width: 100%;
  max-width: 1366px;
  margin: 0;
  margin-left: auto;
  margin-right: auto; }

/*! Body Padding */
.padding-body {
  padding-left: 9em;
  padding-right: 9em; }

@media (max-width: 1300px) {
  .padding-body {
    padding-left: 8em;
    padding-right: 8em; } }

@media (max-width: 1200px) {
  .padding-body {
    padding-left: 7em;
    padding-right: 7em; } }

@media (max-width: 1100px) {
  .padding-body {
    padding-left: 5em;
    padding-right: 5em; } }

@media (max-width: 992px) {
  .padding-body {
    padding-left: 3em;
    padding-right: 3em; } }

@media (max-width: 900px) {
  .padding-body {
    padding-left: 2em;
    padding-right: 2em; } }

@media (max-width: 767px) {
  .padding-body {
    padding-left: 2em;
    padding-right: 2em; } }

@media (max-width: 576px) {
  .padding-body {
    padding-left: 2em;
    padding-right: 2em; } }

.padding-body-leftonly {
  padding-left: 9em; }

@media (max-width: 1300px) {
  .padding-body-leftonly {
    padding-left: 8em; } }

@media (max-width: 1200px) {
  .padding-body-leftonly {
    padding-left: 7em; } }

@media (max-width: 1100px) {
  .padding-body-leftonly {
    padding-left: 5em; } }

@media (max-width: 992px) {
  .padding-body-leftonly {
    padding-left: 3em; } }

@media (max-width: 900px) {
  .padding-body-leftonly {
    padding-left: 2em; } }

@media (max-width: 767px) {
  .padding-body-leftonly {
    padding-left: 2em; } }

@media (max-width: 576px) {
  .padding-body-leftonly {
    padding-left: 2em;
    padding-right: 2em; } }

/*! Limit Character Width */
.limitcharacterwidth {
  max-width: 50em;
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 767px) {
  .limitcharacterwidth {
    max-width: 100%; } }

/*! Alert */
.alert {
  font-size: 0.8em !important;
  text-transform: uppercase;
  line-height: 110%;
  font-weight: 700;
  letter-spacing: 0.015em;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  border-radius: 0;
  margin: 0;
  padding: 0;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  padding-left: 2em;
  padding-right: 2em;
  z-index: 999; }

@media (max-width: 1200px) {
  .alert {
    font-size: 0.85em; } }

.alert a {
  color: #fff;
  text-decoration: underline; }

.alert a:hover {
  opacity: 0.8;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.alert p {
  margin: 0;
  display: inline; }

/*! Top Navigation */
.topnavigation {
  padding-left: 4em;
  padding-right: 4em;
  padding-top: 1.5em;
  padding-bottom: 0.5em;
  position: relative;
  top: 0;
  z-index: 100;
  margin-bottom: 1em; }

@media (max-width: 1300px) {
  .topnavigation {
    padding-left: 2em;
    padding-right: 2em;
    font-size: 0.95em; } }

@media (max-width: 1100px) {
  .topnavigation {
    padding-left: 1em;
    padding-right: 0.5em;
    font-size: 0.9em; } }

@media (max-width: 992px) {
  .topnavigation {
    display: none; } }

.topnavigation_text {
  padding: 0;
  margin: 0;
  padding-left: 2em;
  padding-right: 2em; }

@media (max-width: 1100px) {
  .topnavigation_text {
    padding-left: 1em;
    padding-right: 1em; } }

.topnavigation_center {
  display: flex;
  justify-content: center;
  align-items: center; }

.topnavigation_logo {
  text-align: left;
  padding: 0;
  margin: 0;
  padding-right: 2em;
  padding-top: 1em; }

@media (max-width: 992px) {
  .topnavigation_logo {
    padding-left: 1em; } }

@media (max-width: 767px) {
  .topnavigation_logo {
    padding-left: 0.75em; } }

.topnavigation_logo img {
  margin: 0;
  width: auto;
  height: 3.75em; }

@media (max-width: 1200px) {
  .topnavigation_logo img {
    height: 3em; } }

@media (max-width: 767px) {
  .topnavigation_logo img {
    height: 2.75em; } }

@media (max-width: 576px) {
  .topnavigation_logo img {
    height: 2.5em; } }

@media (max-width: 480px) {
  .topnavigation_logo img {
    height: 1.75em; } }

.topnavigation_links {
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: 700; }

.topnavigation_contact {
  text-align: right;
  padding: 0;
  margin: 0;
  padding-left: 2em; }

.topnavigation ul {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0; }

.topnavigation li {
  display: inline-block;
  padding: 0;
  margin: 0;
  padding-left: 1em;
  padding-right: 1em; }

@media (max-width: 1200px) {
  .topnavigation li {
    padding-left: 0.75em;
    padding-right: 0.75em; } }

.topnavigation li,
.topnavigation li a {
  color: #000;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent; }

.topnavigation li.active a {
  color: #404040;
  border-bottom: 2px solid #aa353e; }

.topnavigation li a:hover {
  color: #404040;
  border-bottom: 2px solid #aa353e;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.banner_container_withphoto .topnavigation li,
.banner_container_withphoto .topnavigation li a {
  color: #fff;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent; }

.banner_container_withphoto .topnavigation li.active a {
  color: #ededed;
  border-bottom: 2px solid #ededed; }

.banner_container_withphoto .topnavigation li a:hover {
  color: #ededed;
  border-bottom: 2px solid #ededed; }

/*! Mobile Navigation */
.topnavigation_mobile {
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-top: 0.875em;
  padding-bottom: 0.25em;
  position: relative;
  top: 0;
  z-index: 100;
  margin-bottom: 1em;
  display: none;
  position: relative;
  z-index: 1000; }

@media (max-width: 992px) {
  .topnavigation_mobile {
    display: block; } }

.topnavigation_mobile_menu {
  text-align: right;
  margin-top: 0.5em; }

@media (max-width: 480px) {
  .topnavigation_mobile_menu {
    margin-top: 0.5em; } }

.topnavigation_mobile_menu_active {
  opacity: 0.9; }

.topnavigation_mobile_menu img {
  width: 2em;
  top: 4em;
  margin-top: 0.5em;
  cursor: pointer; }

.topnavigation_mobile_menu_show {
  display: none;
  position: relative;
  right: 0;
  background-color: #404040;
  color: #fff;
  font-weight: 700;
  margin-top: 1em;
  padding-left: 0em;
  padding-right: 0em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  box-shadow: -4px 4px 12px 0px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  text-align: right; }

.topnavigation_mobile_menu_show ul {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0; }

.topnavigation_mobile_menu_show li {
  display: block;
  padding: 0;
  margin: 0;
  line-height: 110%; }

.topnavigation_mobile_menu_show li a {
  color: #fff;
  display: block;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.875em;
  padding-bottom: 0.875em; }

.topnavigation_mobile_menu_show li.active a {
  text-decoration: underline; }

.topnavigation_mobile_menu_show li a:hover {
  color: #fff;
  background: #545454; }

/*! Slick - Hide until ready, so it doesn't flash all the screens on load */
.carousel div {
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease; }

.carousel.slick-initialized div {
  visibility: visible; }

/*! Dots */
.slick-dots {
  margin-top: -12em;
  z-index: 200; }

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

@media (max-width: 767px) {
  .slick-dots li {
    margin: 0 8px; } }

@media (max-width: 576px) {
  .slick-dots li {
    margin: 0 7px; } }

.slick-dots li button:before {
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  opacity: 1; }

@media (max-width: 767px) {
  .slick-dots li button:before {
    font-size: 14px;
    line-height: 14px; } }

@media (max-width: 576px) {
  .slick-dots li button:before {
    font-size: 16px;
    line-height: 16px; } }

.slick-dots li button:hover:before {
  color: #000;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.slick-dots li.slick-active button:before {
  color: #333333;
  opacity: 1; }

.slick-dots {
  position: absolute;
  bottom: 5px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }

/*! Arrows */
.slick-next:before, .slick-prev:before {
  font-family: slick;
  font-size: 48px;
  line-height: 1;
  color: #fff;
  opacity: .75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-next:hover:before,
.slick-prev:hover:before {
  color: #000;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.slick-next {
  right: 20px; }

@media (max-width: 640px) {
  .slick-next {
    right: 15px; } }

.slick-prev {
  left: 20px; }

@media (max-width: 640px) {
  .slick-prev {
    left: 15px; } }

.slick-next, .slick-prev {
  position: absolute;
  display: block;
  height: auto !important;
  width: 40px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: 0 0;
  color: transparent;
  top: 42%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: 0;
  z-index: 1000; }

@media (max-width: 1200px) {
  .slick-next, .slick-prev {
    height: auto;
    width: 36px;
    top: 36%; } }

@media (max-width: 992px) {
  .slick-next, .slick-prev {
    height: auto;
    width: 30px;
    top: 34%; } }

@media (max-width: 900px) {
  .slick-next, .slick-prev {
    height: auto;
    width: 27px;
    top: 30%; } }

@media (max-width: 576px) {
  .slick-next, .slick-prev {
    height: auto;
    width: 22px;
    top: 24%; } }

.slick-next:before {
  display: none; }

.slick-prev:before {
  display: none; }

.slick-next img,
.slick-prev img {
  opacity: 1; }

.slick-next img:hover,
.slick-prev img:hover {
  opacity: 0.9;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.slick-list.dragging {
  cursor: -webkit-grab !important;
  cursor: grab !important; }

.slick-dotted.slick-slider {
  margin-bottom: 0px; }

/*! POPUPS */
/*! Popup - Text Only */
.white-popup {
  position: relative;
  background: #fff;
  width: auto;
  max-width: 70%;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3em;
  padding-bottom: 2em;
  padding-left: 1.5em;
  padding-right: 1.5em; }

@media screen and (max-width: 1100px) {
  .white-popup {
    max-width: 75%; } }

@media screen and (max-width: 900px) {
  .white-popup {
    max-width: 80%; } }

@media screen and (max-width: 800px) {
  .white-popup {
    max-width: 85%; } }

@media screen and (max-width: 640px) {
  .white-popup {
    margin-top: 8em;
    max-width: 90%; } }

.white-popup img {
  width: 100%;
  height: auto; }

/*! Video iFrame */
.mfp-iframe-holder .mfp-content {
  width: 80%;
  max-width: 1920px; }

@media (max-width: 1100px) {
  .mfp-iframe-holder .mfp-content {
    width: 85%; } }

@media (max-width: 992px) {
  .mfp-iframe-holder .mfp-content {
    width: 90%; } }

@media (max-width: 767px) {
  .mfp-iframe-holder .mfp-content {
    width: 95%; } }

@media (max-width: 576px) {
  .mfp-iframe-holder .mfp-content {
    width: 100%; } }

/*! Close Button */
.mfp-close {
  margin: 0; }

.mfp-close-btn-in .mfp-close {
  color: #000 !important;
  background-color: transparent !important; }

.mfp-close-btn-in .mfp-close:hover {
  color: #333333 !important;
  background-color: transparent !important; }

.mfp-close:active {
  top: 0; }

.mfp-iframe-holder .mfp-close {
  top: -39px; }

.mfp-close {
  opacity: 0.9; }

.popupclose {
  cursor: pointer; }

/*! Arrows */
@media (max-width: 767px) {
  .mfp-arrow-right:after {
    margin-left: 64px; }
  .mfp-arrow-right:before {
    margin-left: 64px; }
  .mfp-arrow-left:after {
    margin-left: 4px; }
  .mfp-arrow-left:before {
    margin-left: 4px; } }

.mfp-arrow-right::before {
  border-left: 0; }

.mfp-arrow-left::before {
  border-left: 0;
  border-right: 0; }

/* Fade In */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out; }

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8; }

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out; }

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

/* z-index */
.mfp-bg {
  z-index: 10042; }

.mfp-wrap {
  z-index: 10043; }

/*! Banner */
.yellowtext {
  color: #aa353e; }

.banner_container {
  position: relative;
  z-index: 0; }

.banner_container_withphoto {
  background-color: black; }

.banner_photo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  z-index: 0; }

.banner_photo_lighter {
  opacity: 0.65; }

.banner {
  position: relative;
  min-height: 10em;
  z-index: 100; }

.banner_inside {
  margin-top: 2em;
  margin-bottom: 3em; }

@media (max-width: 767px) {
  .banner_inside {
    margin-bottom: 2em; } }

.banner_text {
  padding-top: 6em;
  padding-bottom: 4em;
  text-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.2); }

@media (max-width: 992px) {
  .banner_text {
    padding-top: 4em; } }

@media (max-width: 576px) {
  .banner_text {
    padding-top: 3em;
    padding-bottom: 1em; } }

.banner_text_home {
  padding-top: 8em;
  padding-bottom: 6em;
  text-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.2); }

@media (max-width: 992px) {
  .banner_text {
    padding-top: 6em; } }

@media (max-width: 576px) {
  .banner_text {
    padding-top: 3em;
    padding-bottom: 1em; } }

.banner_text_inside {
  padding-top: 12em;
  padding-bottom: 12em; }

@media (max-width: 1400px) {
  .banner_text_inside {
    padding-top: 11em;
    padding-bottom: 11em; } }

@media (max-width: 1200px) {
  .banner_text_inside {
    padding-top: 10em;
    padding-bottom: 10em; } }

@media (max-width: 1100px) {
  .banner_text_inside {
    padding-top: 9em;
    padding-bottom: 9em; } }

@media (max-width: 992px) {
  .banner_text_inside {
    padding-top: 8em;
    padding-bottom: 8em; } }

@media (max-width: 767px) {
  .banner_text_inside {
    padding-top: 5em;
    padding-bottom: 5em; } }

@media (max-width: 576px) {
  .banner_text_inside {
    padding-top: 3em;
    padding-bottom: 1em; } }

.banner_text_inside_small {
  padding-top: 8em;
  padding-bottom: 8em; }

@media (max-width: 1400px) {
  .banner_text_inside_small {
    padding-top: 7em;
    padding-bottom: 7em; } }

@media (max-width: 1200px) {
  .banner_text_inside_small {
    padding-top: 6em;
    padding-bottom: 6em; } }

@media (max-width: 1100px) {
  .banner_text_inside_small {
    padding-top: 5em;
    padding-bottom: 5em; } }

@media (max-width: 992px) {
  .banner_text_inside_small {
    padding-top: 4em;
    padding-bottom: 4em; } }

@media (max-width: 767px) {
  .banner_text_inside_small {
    padding-top: 3em;
    padding-bottom: 3em; } }

@media (max-width: 576px) {
  .banner_text_inside_small {
    padding-top: 3em;
    padding-bottom: 1em; } }

.banner_title {
  color: #404040;
  margin-bottom: 1.5em; }

.banner h1,
.banner h2,
.banner h3,
.banner h4,
.banner h5,
.banner h6 {
  line-height: 120%;
  color: #404040;
  margin: 0; }

.banner_container_withphoto .banner_title,
.banner_container_withphoto .banner h1,
.banner_container_withphoto .banner h2,
.banner_container_withphoto .banner h3,
.banner_container_withphoto .banner h4,
.banner_container_withphoto .banner h5,
.banner_container_withphoto .banner h6 {
  color: #fff; }

.homebanner h1 {
  font-size: 3.5em; }

@media (max-width: 992px) {
  .homebanner h1 {
    font-size: 3.25em; } }

@media (max-width: 900px) {
  .homebanner h1 {
    font-size: 3em; } }

@media (max-width: 767px) {
  .homebanner h1 {
    font-size: 2.5em; } }

@media (max-width: 576px) {
  .homebanner h1 {
    font-size: 2em; } }

.banner_description {
  color: #404040;
  font-size: 1.125em;
  font-weight: 700;
  margin: 0; }

.banner_button {
  margin-top: 2em; }

.banner_container_withphoto .banner_description {
  color: #fff; }

.banner_form {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center; }

.banner_form_button {
  margin-top: .125em; }

.banner_form input {
  margin-left: 0.25em;
  margin-right: 0.25em; }

.banner_form_bottom {
  font-size: 0.75em;
  margin-top: 0.25em; }

.banner_bottom_photo {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 2em; }

.banner_bottom_photo img {
  width: 100%; }

.banner_photo_right {
  text-align: center; }

@media (max-width: 576px) {
  .banner_photo_right {
    margin-top: 2em; } }

.banner_photo_verticalphone img {
  width: auto;
  height: 24em; }

.banner_button {
  margin-top: 2em; }

.banner_text_center {
  display: flex;
  justify-content: center;
  align-items: center; }

.banner_pricing_bottom {
  margin-top: 1.5em; }

/*! HOME */
/*! HOME - BENEFITS */
.home_benefits {
  padding-top: 1em;
  padding-bottom: 1em; }

.home_benefits_row {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 900px) {
  .home_benefits_row {
    padding-top: 1.5em;
    padding-bottom: 1.5em; } }

@media (max-width: 576px) {
  .home_benefits_row {
    padding-top: 1.5em;
    padding-bottom: 1.5em; } }

.home_benefits_text {
  text-align: left;
  padding-top: 2em;
  padding-bottom: 1em;
  padding-left: 0;
  padding-right: 2em; }

@media (max-width: 1300px) {
  .home_benefits_text {
    padding-top: 1em;
    padding-bottom: 0.5em; } }

@media (max-width: 576px) {
  .home_benefits_text {
    padding-top: 0em;
    padding-bottom: 0em;
    padding-left: 0;
    padding-right: 0; } }

.home_benefits_text_right {
  padding-left: 2em;
  padding-right: 0; }

@media (max-width: 576px) {
  .home_benefits_text_right {
    padding-left: 0;
    padding-right: 0; } }

.home_benefits_photo {
  margin: 0;
  padding: 0; }

@media (max-width: 576px) {
  .home_benefits_photo {
    margin-bottom: 1em; } }

.home_benefits_photo_photo {
  width: 100%;
  padding-top: 66%;
  position: relative; }

@media (max-width: 900px) {
  .home_benefits_photo_photo {
    padding-top: 80%; } }

@media (max-width: 576px) {
  .home_benefits_photo_photo {
    padding-top: 60%; } }

.home_benefits_title h1,
.home_benefits_title h2,
.home_benefits_title h3,
.home_benefits_title h4,
.home_benefits_title h5,
.home_benefits_title h6 {
  text-align: left;
  color: #aa353e !important; }

.home_benefits_button {
  margin-top: 2.5em; }

@media (max-width: 1400px) {
  .home_benefits_button {
    margin-top: 2em; } }

@media (max-width: 1300px) {
  .home_benefits_button {
    margin-top: 1.5em; } }

@media (max-width: 992px) {
  .home_benefits_button {
    margin-top: 1em; } }

@media (max-width: 576px) {
  .home_benefits_button {
    margin-top: 1em; } }

/*! HOME - RED BOX */
.home_redbox {
  background-color: #aa353e;
  color: #fff;
  padding-top: 3.5em;
  padding-bottom: 3em; }

@media (max-width: 992px) {
  .home_redbox {
    padding-top: 2.5em;
    padding-bottom: 2em; } }

@media (max-width: 576px) {
  .home_redbox {
    padding-top: 1.5em;
    padding-bottom: 1em; } }

.home_redbox h1,
.home_redbox h2,
.home_redbox h3,
.home_redbox h4,
.home_redbox h5,
.home_redbox h6 {
  color: #000 !important;
  font-size: 2em;
  line-height: 115%; }

@media (max-width: 1300px) {
  .home_redbox h1,
  .home_redbox h2,
  .home_redbox h3,
  .home_redbox h4,
  .home_redbox h5,
  .home_redbox h6 {
    font-size: 1.75em; } }

@media (max-width: 1200px) {
  .home_redbox h1,
  .home_redbox h2,
  .home_redbox h3,
  .home_redbox h4,
  .home_redbox h5,
  .home_redbox h6 {
    font-size: 1.5em; } }

@media (max-width: 992px) {
  .home_redbox h1,
  .home_redbox h2,
  .home_redbox h3,
  .home_redbox h4,
  .home_redbox h5,
  .home_redbox h6 {
    font-size: 1.25em; } }

@media (max-width: 576px) {
  .home_redbox h1,
  .home_redbox h2,
  .home_redbox h3,
  .home_redbox h4,
  .home_redbox h5,
  .home_redbox h6 {
    font-size: 1.75em; } }

.home_redbox_icon {
  margin-bottom: 2.5em; }

@media (max-width: 1200px) {
  .home_redbox_icon {
    margin-bottom: 2em; } }

@media (max-width: 992px) {
  .home_redbox_icon {
    margin-bottom: 1em; } }

@media (max-width: 576px) {
  .home_redbox_icon {
    margin-bottom: 1em;
    margin-top: 1em; } }

.home_redbox_icon,
.home_redbox_icon img {
  width: auto;
  height: 3.25em; }

@media (max-width: 1200px) {
  .home_redbox_icon,
  .home_redbox_icon img {
    height: 3em; } }

@media (max-width: 992px) {
  .home_redbox_icon,
  .home_redbox_icon img {
    height: 2.5em; } }

@media (max-width: 767px) {
  .home_redbox_icon,
  .home_redbox_icon img {
    height: 2.25em; } }

@media (max-width: 576px) {
  .home_redbox_icon,
  .home_redbox_icon img {
    height: 2.25em; } }

.home_redbox_title {
  margin-bottom: 0.5em; }

@media (max-width: 992px) {
  .home_redbox_title {
    margin-bottom: 0.25em; } }

.home_redbox_description {
  font-size: 0.9em;
  padding-right: 2em;
  margin-bottom: 1em; }

@media (max-width: 767px) {
  .home_redbox_description {
    font-size: 0.9em;
    padding-right: 1em; } }

@media (max-width: 576px) {
  .home_redbox_description {
    font-size: 1em;
    padding-right: 0em;
    margin-bottom: 2em; } }

/*! HOME - WHY */
.home_why {
  background-color: #ededed;
  color: #474747;
  padding-top: 5em;
  padding-bottom: 3em; }

@media (max-width: 1300px) {
  .home_why {
    padding-top: 4em;
    padding-bottom: 1.5em; } }

@media (max-width: 1100px) {
  .home_why {
    padding-top: 3em;
    padding-bottom: 1.5em; } }

@media (max-width: 576px) {
  .home_why {
    padding-top: 2em;
    padding-bottom: 1.5em; } }

.home_why_description {
  text-align: center; }

.home_why_item_row {
  margin-top: 4em; }

@media (max-width: 1300px) {
  .home_why_item_row {
    margin-top: 3em; } }

.home_why_item {
  margin-bottom: 3em; }

@media (max-width: 767px) {
  .home_why_item {
    margin-bottom: 2.5em; } }

.home_why_icon {
  padding-left: 0; }

.home_why_icon_circle {
  width: 10em;
  height: 10em;
  border-radius: 10em; }

@media (max-width: 1300px) {
  .home_why_icon_circle {
    width: 8em;
    height: 8em;
    border-radius: 8em; } }

@media (max-width: 992px) {
  .home_why_icon_circle {
    width: 6em;
    height: 6em;
    border-radius: 6em; } }

@media (max-width: 767px) {
  .home_why_icon_circle {
    width: 4em;
    height: 4em;
    border-radius: 4em; } }

.home_why_text {
  text-align: left;
  padding-left: 0;
  padding-right: 2em;
  padding-top: 1em; }

@media (max-width: 992px) {
  .home_why_text {
    text-align: left;
    padding-left: 0;
    padding-right: 1em; } }

@media (max-width: 767px) {
  .home_why_text {
    padding-left: 0;
    padding-right: 0; } }

.home_why_text_text {
  padding-left: 1em;
  padding-right: 0.5em; }

@media (max-width: 992px) {
  .home_why_text_text {
    padding-left: 0.5em;
    padding-right: 0.5em; } }

@media (max-width: 767px) {
  .home_why_text_text {
    padding-left: 0.5em;
    padding-right: 0em; } }

.home_why_text h1,
.home_why_text h2,
.home_why_text h3,
.home_why_text h4,
.home_why_text h5,
.home_why_text h6 {
  text-align: left; }

.home_why_text_link {
  font-weight: 700;
  font-size: 0.85em;
  line-height: 115%;
  letter-spacing: 0.02em;
  margin-top: 1em;
  color: #404040; }

@media (max-width: 767px) {
  .home_why_text_link {
    margin-top: 0.5em; } }

.home_why_title h2 {
  color: #aa353e !important; }

/*! HOME - SLOGAN */
.home_slogan {
  background-color: #ededed;
  padding-top: 4em;
  padding-bottom: 4em; }

@media (max-width: 767px) {
  .home_slogan {
    padding-top: 2em;
    padding-bottom: 1.5em; } }

.home_slogan h3 {
  line-height: 130%; }

.home_slogan h1,
.home_slogan h2,
.home_slogan h3,
.home_slogan h4,
.home_slogan h5,
.home_slogan h6 {
  color: #aa353e !important;
  font-size: 2.5em; }

@media (max-width: 1300px) {
  .home_slogan h1,
  .home_slogan h2,
  .home_slogan h3,
  .home_slogan h4,
  .home_slogan h5,
  .home_slogan h6 {
    font-size: 2.125em; } }

@media (max-width: 1200px) {
  .home_slogan h1,
  .home_slogan h2,
  .home_slogan h3,
  .home_slogan h4,
  .home_slogan h5,
  .home_slogan h6 {
    font-size: 2em; } }

@media (max-width: 992px) {
  .home_slogan h1,
  .home_slogan h2,
  .home_slogan h3,
  .home_slogan h4,
  .home_slogan h5,
  .home_slogan h6 {
    font-size: 1.75em; } }

@media (max-width: 767px) {
  .home_slogan h1,
  .home_slogan h2,
  .home_slogan h3,
  .home_slogan h4,
  .home_slogan h5,
  .home_slogan h6 {
    font-size: 1.5em; } }

/*! HOME - CLIENT LOGOS */
.home_logos {
  padding-top: 2em;
  padding-bottom: 2em; }

@media (max-width: 576px) {
  .home_logos {
    padding-top: 1em;
    padding-bottom: 2em; } }

.clientlogos_slide {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em; }

@media (max-width: 576px) {
  .clientlogos_slide {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em; } }

.home_logos .slick-dots {
  bottom: -1em; }

@media (max-width: 576px) {
  .home_logos .slick-dots {
    bottom: 0em; } }

/*! FEATURES */
.features_slogan {
  background-color: #d9d9d9;
  padding-top: 2em;
  padding-bottom: 0.7em; }

@media (max-width: 767px) {
  .features_slogan {
    padding-top: 1.5em;
    padding-bottom: 0.325em; } }

.features_button {
  margin-top: 2em; }

.features_slideshow {
  padding-bottom: 0em;
  margin-bottom: 1em; }

.features_slideshow_photo {
  padding: 0; }

.features_slideshow_text {
  padding-top: 2em;
  padding-bottom: 3em; }

@media (max-width: 767px) {
  .features_slideshow_text {
    padding-top: 1em;
    padding-bottom: 3em; } }

.features_slide_description_col {
  z-index: 100; }

.features_slide_photo {
  background-color: #fff;
  color: #fff;
  min-height: 30em;
  margin-bottom: 0.625em; }

@media (max-width: 1300px) {
  .features_slide_photo {
    min-height: 28em; } }

@media (max-width: 1200px) {
  .features_slide_photo {
    min-height: 26em; } }

@media (max-width: 1100px) {
  .features_slide_photo {
    min-height: 24em; } }

@media (max-width: 992px) {
  .features_slide_photo {
    min-height: 20em; } }

@media (max-width: 900px) {
  .features_slide_photo {
    min-height: 16em; } }

@media (max-width: 767px) {
  .features_slide_photo {
    min-height: 18em; } }

@media (max-width: 640px) {
  .features_slide_photo {
    min-height: 30em; } }

.features_slide_description {
  padding-top: 2em !important;
  padding-bottom: 4em !important; }

@media (max-width: 1200px) {
  .features_slide_description {
    padding-top: 2em !important;
    padding-bottom: 3.5em !important; } }

@media (max-width: 900px) {
  .features_slide_description {
    padding-top: 2em !important;
    padding-bottom: 3.25em !important; } }

@media (max-width: 767px) {
  .features_slide_description {
    padding-top: 2em !important;
    padding-bottom: 3em !important; } }

/*! CASE STUDIES */
.casestudies {
  padding-top: 1em;
  padding-bottom: 1em; }

.casestudies_stars img {
  width: 50%;
  margin-bottom: 1.75em; }

@media (max-width: 767px) {
  .casestudies_stars img {
    width: 40%;
    margin-bottom: 1em; } }

@media (max-width: 576px) {
  .casestudies_stars img {
    width: 50%;
    margin-bottom: 1em; } }

.casestudies_button {
  margin-top: 1.5em; }

.casestudies_item {
  margin-bottom: 3em; }

@media (max-width: 1200px) {
  .casestudies_item {
    margin-bottom: 2.5em; } }

@media (max-width: 992px) {
  .casestudies_item {
    margin-bottom: 2em; } }

@media (max-width: 767px) {
  .casestudies_item {
    margin-bottom: 1em; } }

.casestudies_item .blog_title {
  padding-right: 1em; }

@media (max-width: 576px) {
  .casestudies_item .blog_title {
    padding-right: 0em; } }

/*! PRICING */
.pricing {
  padding-top: 2em;
  padding-bottom: 3em; }

@media (max-width: 1200px) {
  .pricing {
    padding-top: 2em;
    padding-bottom: 2em; } }

@media (max-width: 1100px) {
  .pricing {
    padding-top: 1em;
    padding-bottom: 1em; } }

@media (max-width: 992px) {
  .pricing {
    padding-top: 0em;
    padding-bottom: 1em; } }

.pricing_column_border {
  border: 1px solid #000; }

.pricing_column {
  padding-top: 2.125em;
  padding-bottom: 1.5em;
  padding-left: 2em;
  padding-right: 2em; }

@media (max-width: 1200px) {
  .pricing_column {
    padding-left: 1.5em;
    padding-right: 1.5em; } }

@media (max-width: 992px) {
  .pricing_column {
    padding-left: 1em;
    padding-right: 1em; } }

@media (max-width: 767px) {
  .pricing_column {
    padding-top: 1.5em;
    padding-left: 2em;
    padding-right: 2em; } }

.pricing_chart {
  margin-top: 2em; }

.pricing_column ul {
  list-style: none;
  list-style-type: none !important;
  margin-top: 2em;
  padding-left: 1.5em; }

.pricing_column ul li:before {
  content: "✓" !important;
  margin-right: 0.25em;
  margin-left: -1em;
  text-indent: 1.5em;
  font-size: 1.5em !important;
  line-height: 100%;
  color: #404040; }

.pricing_column li {
  color: #404040;
  line-height: 120%; }

.pricing_title h1,
.pricing_title h2,
.pricing_title h3,
.pricing_title h4,
.pricing_title h5,
.pricing_title h6,
.pricing_title {
  color: #333333; }

@media (max-width: 767px) {
  .pricing_title {
    font-size: 1.25em; } }

.background-color-bluehover .pricing_title h1,
.background-color-bluehover .pricing_title h2,
.background-color-bluehover .pricing_title h3,
.background-color-bluehover .pricing_title h4,
.background-color-bluehover .pricing_title h5,
.background-color-bluehover .pricing_title h6,
.background-color-bluehover .pricing_title {
  color: #333333; }

.pricing_description {
  color: #404040;
  margin-bottom: 1.5em; }

.pricing_price {
  color: #404040;
  font-weight: 700;
  margin-bottom: 1em; }

.pricing_price_enterprise {
  color: #404040;
  font-weight: 700;
  margin-bottom: 1em; }

.price_big {
  font-size: 2.25em; }

.price_small {
  font-size: 1em; }

.pricing_subtitle {
  color: #404040;
  font-weight: 700; }

.pricing_button {
  margin-top: 2em; }

.pricing_column_left {
  margin-top: 3em;
  border-radius: 1.5em 0 0 0; }

@media (max-width: 767px) {
  .pricing_column_left {
    margin-top: 2em;
    border-radius: 1.5em 1.5em 0 0; } }

.pricing_column_center {
  margin-top: 1em;
  padding: 0;
  position: relative;
  box-shadow: 2px 6px 12px 10px rgba(0, 0, 0, 0.3);
  border-radius: 1.5em 1.5em 0 0; }

@media (max-width: 767px) {
  .pricing_column_center {
    margin-top: 2em;
    box-shadow: none;
    border-radius: 1.5em 1.5em 0 0; } }

.pricing_tab {
  background-color: #f0cfd2;
  background-image: linear-gradient(#f0cfd2, #d9858c);
  border-radius: 1.5em 1.5em 0 0;
  border-bottom: 1px solid #000;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center; }

.pricing_tab_text {
  font-size: 1.25em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 2px #aa353e; }

.pricing_column_right {
  margin-top: 3em;
  border-radius: 0 1.5em 0 0; }

@media (max-width: 767px) {
  .pricing_column_right {
    margin-top: 2em;
    border-radius: 1.5em 1.5em 0 0; } }

.pricing_ribbon {
  margin-top: 0em;
  position: absolute;
  top: -1em;
  left: 0em;
  margin-left: -1em;
  margin-right: -1em;
  z-index: 100; }

.pricing_column_text {
  position: relative;
  z-index: 200;
  padding-top: 0em; }

@media (max-width: 767px) {
  .pricing_column_text {
    padding-top: 6em; } }

@media (max-width: 576px) {
  .pricing_column_text {
    padding-top: 4em; } }

/*! FAQ */
.faq_text {
  margin-top: 4em;
  margin-bottom: 6em;
  font-weight: 700; }

@media (max-width: 992px) {
  .faq_text {
    margin-top: 3em;
    margin-bottom: 5em; } }

@media (max-width: 576px) {
  .faq_text {
    margin-top: 2em;
    margin-bottom: 4em; } }

@media (max-width: 992px) {
  .faq_icon {
    --bs-gutter-x: 1em; } }

.faq_icon img {
  width: 3em;
  margin-right: 1em;
  margin-top: -0.5em; }

@media (max-width: 992px) {
  .faq_icon img {
    margin-right: 0em;
    margin-top: -0.5em; } }

@media (max-width: 767px) {
  .faq_icon img {
    width: 2em; } }

.faq_question {
  padding-top: 1.5em;
  padding-bottom: 0.625em;
  padding-left: 0.25em;
  border-bottom: 2px solid #404040;
  color: #404040;
  cursor: pointer; }

@media (max-width: 767px) {
  .faq_question {
    padding-top: 1em; } }

.faq_question:hover {
  color: #000;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.faq_answer {
  background-color: #f0cfd2;
  padding-top: 1em;
  padding-bottom: 0.5em;
  padding-left: 0.25em;
  padding-right: 1em;
  display: none;
  color: #000;
  border-bottom: 2px solid #404040; }

@media (max-width: 767px) {
  .faq_answer {
    padding-top: .5em;
    padding-bottom: 0; } }

.faq_answer_text {
  padding-top: 0.4em; }

.faq_answer a {
  text-decoration: underline; }

.faq_text {
  padding-top: 0.0125em; }

/*! TTN */
/*! Mission */
.ttn_mission_container {
  margin-top: 0; }

@media (max-width: 767px) {
  .ttn_mission_container {
    padding-top: 0em;
    padding-bottom: 1em; } }

@media (max-width: 767px) {
  .ttn_mission_photo_container {
    --bs-gutter-x: 0; } }

.ttn_mission_photo_row {
  --bs-gutter-x: 0; }

.ttn_mission_photo_left {
  margin-bottom: 1em;
  padding-right: 0.5em;
  padding-left: 0; }

@media (max-width: 767px) {
  .ttn_mission_photo_left {
    padding-right: 0em; } }

.ttn_mission_photo_left_photo {
  width: 100%;
  padding-top: 80%;
  position: relative; }

@media (max-width: 767px) {
  .ttn_mission_photo_left_photo {
    padding-top: 40%; } }

.ttn_mission_photo_right {
  margin-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0; }

@media (max-width: 767px) {
  .ttn_mission_photo_right {
    padding-left: 0em; } }

.ttn_mission_photo_right_photo {
  width: 100%;
  padding-top: 80%;
  position: relative; }

@media (max-width: 767px) {
  .ttn_mission_photo_right_photo {
    padding-top: 40%; } }

.ttn_mission_photo_bottom {
  padding-left: 0;
  padding-right: 0; }

.ttn_mission_text {
  margin-top: 0; }

@media (max-width: 767px) {
  .ttn_mission_text {
    margin-top: 1em; } }

.ttn_mission_description {
  padding-right: 3em; }

@media (max-width: 1200px) {
  .ttn_mission_description {
    padding-right: 2em; } }

@media (max-width: 1100px) {
  .ttn_mission_description {
    padding-right: 1em; } }

@media (max-width: 992px) {
  .ttn_mission_description {
    padding-right: 0; } }

@media (max-width: 767px) {
  .ttn_mission_description {
    padding-right: 0; } }

.ttn_mission_row {
  padding-top: 0; }

/*! Team */
.team_row {
  padding-left: 4em;
  padding-right: 4em;
  margin-top: 2em; }

@media (max-width: 767px) {
  .team_row {
    padding-left: 3em;
    padding-right: 3em; } }

@media (max-width: 576px) {
  .team_row {
    padding-left: 4em;
    padding-right: 4em; } }

.team_photo {
  padding-left: 3em;
  padding-right: 3em;
  margin-bottom: 1em; }

@media (max-width: 992px) {
  .team_photo {
    padding-left: 2em;
    padding-right: 2em; } }

@media (max-width: 767px) {
  .team_photo {
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0.5em; } }

@media (max-width: 576px) {
  .team_photo {
    padding-left: 3em;
    padding-right: 3em;
    margin-bottom: 0.5em; } }

@media (max-width: 480px) {
  .team_photo {
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0.5em; } }

.team_photo_photo {
  margin-left: auto;
  margin-right: auto;
  border-radius: 30em; }

.team_social {
  margin-top: 0.25em;
  text-align: center; }

.team_social img {
  width: 1.25em;
  height: 1.25em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  opacity: 0.5; }

@media (max-width: 767px) {
  .team_social img {
    margin-left: 0.325em;
    margin-right: 0.325em; } }

.team_social img:hover {
  opacity: 1;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

/*! LISTING */
.advertise_filters {
  background-color: #ededed;
  color: #000;
  padding-top: 2em;
  padding-bottom: 2em; }

.advertise_filters_padding {
  padding-left: 4.5em;
  padding-right: 4.5em; }

@media (max-width: 1300px) {
  .advertise_filters_padding {
    padding-left: 2.5em;
    padding-right: 2.5em; } }

@media (max-width: 1100px) {
  .advertise_filters_padding {
    padding-left: 1.5em;
    padding-right: 1.5em; } }

@media (max-width: 992px) {
  .advertise_filters_padding {
    padding-left: 0.5em;
    padding-right: 0.5em; } }

@media (max-width: 992px) {
  .advertise_filters_padding {
    padding-left: 0.5em;
    padding-right: 0.5em; } }

.advertise_filters a {
  color: #000 !important; }

.advertise_filters a:hover {
  color: #aa353e !important; }

.advertise_filters_span {
  padding-right: 2em; }

.advertise_listing {
  background-color: #ededed;
  padding-top: 2em;
  padding-bottom: 1em; }

.advertise_listing_item {
  margin-bottom: 2em;
  box-shadow: 0.5em 0.5em 1em rgba(0, 0, 0, 0.25); }

.advertise_listing_photo {
  min-height: 10em;
  position: relative; }

.advertise_listing_photo_half {
  min-height: 10em; }

.advertise_listing_photo_fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.advertise_listing_text {
  background-color: #fff;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
  position: relative; }

@media (max-width: 992px) {
  .advertise_listing_text {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 1.5em;
    padding-right: 1.5em; } }

@media (max-width: 767px) {
  .advertise_listing_text {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1em;
    padding-right: 1em; } }

@media (max-width: 576px) {
  .advertise_listing_text {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em; } }

.advertise_listing_text_half {
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 1.25em;
  padding-right: 1.25em; }

@media (max-width: 992px) {
  .advertise_listing_text_half {
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em; } }

@media (max-width: 767px) {
  .advertise_listing_text_half {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1em;
    padding-right: 1em; } }

@media (max-width: 576px) {
  .advertise_listing_text_half {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em; } }

.advertise_listing_icons {
  margin-bottom: 2em;
  font-size: 0.85em; }

.listing_icon {
  background-color: #404040;
  color: #fff;
  border-radius: 6em;
  line-height: 100%;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 0.6em;
  padding-right: 0.6em;
  margin-right: 0.25em;
  margin-bottom: 1em; }

.advertise_listing_title {
  text-transform: uppercase;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 120%; }

@media (max-width: 1100px) {
  .advertise_listing_title {
    font-size: 1.25em; } }

@media (max-width: 992px) {
  .advertise_listing_title {
    font-size: 1.125em; } }

@media (max-width: 767px) {
  .advertise_listing_title {
    margin-bottom: 0.5em;
    font-size: 1.125em; } }

@media (max-width: 576px) {
  .advertise_listing_title {
    font-size: 1.25em; } }

.advertise_listing_description {
  margin-bottom: 1em;
  padding-bottom: 6em;
  color: #262626;
  font-size: 1.1em; }

@media (max-width: 992px) {
  .advertise_listing_description {
    font-size: 1em;
    padding-bottom: 5em; } }

.advertise_listing_description_half {
  margin-bottom: 0em;
  padding-bottom: 0em; }

.advertise_listing_button {
  margin-top: 4em;
  position: absolute;
  bottom: 2em; }

@media (max-width: 767px) {
  .advertise_listing_item_full {
    padding-left: 1em;
    padding-right: 1em; } }

.advertise_listing_item_full_bottom {
  background-color: #fff;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em; }

@media (max-width: 767px) {
  .advertise_listing_item_full_bottom {
    padding-left: 1em;
    padding-right: 1em;
    text-align: center; } }

.advertise_listing_item_left {
  padding-right: 2em; }

@media (max-width: 767px) {
  .advertise_listing_item_left {
    padding-left: 1em;
    padding-right: 1em; } }

.advertise_listing_item_right {
  padding-left: 2em; }

@media (max-width: 767px) {
  .advertise_listing_item_right {
    padding-left: 1em;
    padding-right: 1em; } }

.advertise_listing_bottom {
  background-color: #fff;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em; }

.advertise_listing_bottom_title {
  margin-bottom: 1.5em; }

.advertise_listing_bottom_columns {
  font-weight: 700;
  text-transform: uppercase; }

.advertise_listing_bottom_columns ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.advertise_listing_bottom_columns li {
  margin: 0;
  padding: 0;
  margin-bottom: 1.5em;
  line-height: 115%; }

.advertise_listing_bottom_col {
  padding-right: 1em; }

@media (max-width: 767px) {
  .advertise_listing_bottom_col {
    padding-right: 0em; } }

/*! FILTER POPUP */
.filter-popup {
  font-size: 1.125em;
  font-weight: bold; }

@media (max-width: 992px) {
  .filter-popup {
    font-size: 1.1em; } }

@media (max-width: 767px) {
  .filter-popup {
    font-size: 1em; } }

.filter-popup h6 {
  font-size: 1.5em; }

.filter-heading {
  margin-bottom: 1.25em; }

.filter-title {
  font-size: 1.1em;
  margin-bottom: 0.75em;
  color: #000; }

.filter-checks {
  margin-bottom: 1.5em; }

.filter-popup input {
  margin-bottom: 0; }

/* Customize the label (the filtercontainer) */
.filtercontainer {
  display: block;
  position: relative;
  padding-left: 2.5em;
  padding-top: 0.5em;
  margin-bottom: 1em;
  cursor: pointer;
  font-size: 1em;
  line-height: 120%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.filtercontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2em;
  width: 2em;
  background-color: #fff;
  border: 3px solid #666; }

/* On mouse-over, add a grey background color */
.filtercontainer:hover input ~ .checkmark {
  background-color: #ccc; }

/* When the checkbox is checked, add a blue background */
.filtercontainer input:checked ~ .checkmark {
  background-color: #aa353e; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.filtercontainer input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.filtercontainer .checkmark:after {
  left: 0.6em;
  top: 0.25em;
  width: 0.5em;
  height: 1em;
  border: solid white;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

/*! COMPANY */
.company_title_container {
  margin-top: 2em;
  padding-top: 2em;
  padding-bottom: 2em; }

.company_title {
  color: #aa353e;
  font-weight: 700;
  font-size: 2.25em;
  text-transform: uppercase;
  line-height: 120%;
  padding-right: 1em; }

@media (max-width: 1100px) {
  .company_title {
    font-size: 2em; } }

@media (max-width: 992px) {
  .company_title {
    font-size: 1.5em; } }

@media (max-width: 767px) {
  .company_title {
    font-size: 1.25em; } }

@media (max-width: 576px) {
  .company_title {
    font-size: 1.5em; } }

.company_icons {
  text-align: right;
  font-size: 1em;
  padding-top: 0.75em; }

@media (max-width: 992px) {
  .company_icons {
    font-size: 1em; } }

@media (max-width: 576px) {
  .company_icons {
    font-size: 0.5em; } }

.company_description {
  margin-top: 1em; }

.company {
  margin-bottom: 2em; }

.company_left {
  padding: 0;
  position: relative;
  margin-bottom: 1em; }

.company_right {
  padding: 0;
  padding-left: 1em;
  position: relative;
  margin-bottom: 1em; }

@media (max-width: 767px) {
  .company_right {
    padding-left: 0; } }

.company_bigheight {
  min-height: 20em; }

@media (max-width: 767px) {
  .company_bigheight {
    min-height: 15em; } }

.company_imagefill {
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.company_map {
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1em;
  right: 0; }

@media (max-width: 767px) {
  .company_map {
    left: 0; } }

.company_text {
  font-size: 1.1em;
  padding-top: 0.5em;
  padding-left: 0.5em; }

@media (max-width: 767px) {
  .company_text {
    font-size: 1em;
    text-align: center;
    padding-left: 0; } }

@media (max-width: 767px) {
  .company_bottomtext {
    text-align: left; } }

.company_buttons {
  font-size: 0.9em;
  margin-top: 1.5em; }

@media (max-width: 767px) {
  .company_buttons {
    text-align: center; } }

.company_buttons button {
  margin: 0;
  margin-bottom: 1em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  margin-left: 0.25em;
  margin-right: 0.25em; }

.company_thumbs {
  min-height: 10em;
  position: relative; }

@media (max-width: 767px) {
  .company_thumbs {
    min-height: 15em; } }

.company_bottomtext_container {
  padding: 0;
  margin: 0;
  border-top: 2px solid #bfbfbf;
  padding-top: 1em; }

.company_bottomtext_container .company_text {
  font-size: 1em; }

.company_bottomtext_container h6 {
  font-size: 1.5em; }

@media (max-width: 992px) {
  .company_bottomtext_container h6 {
    font-size: 1.25em; } }

.company_map_2 {
  height: 20em; }

.company_bigheight_2 {
  height: 34em; }

@media (max-width: 767px) {
  .company_bigheight_2 {
    height: 20em; } }

.company_text_2 {
  padding-top: 22em;
  position: relative; }

.gm-style .gm-style-iw-d {
  padding-right: 1em !important;
  padding-bottom: 0.5em !important; }

/*! ADVERTISE */
.advertising_title h4 {
  margin-bottom: 0.25em; }

.advertising_price {
  font-size: 1.25em;
  color: #333333;
  margin-bottom: 1.25em; }

.advertising_description {
  margin-top: 1em;
  font-size: 1em; }

.advertising_description button {
  margin-top: 0.5em;
  font-size: 1.125em; }

.advertising_description li {
  margin-bottom: 0.5em; }

.advertising_description li ul {
  padding: 0;
  margin-left: 0em;
  margin-top: 0.5em;
  margin-bottom: 0.75em;
  list-style: none; }

.advertising_description li ul li::before {
  padding: 0;
  margin: 0;
  content: "- ";
  padding-right: 0.5em; }

.advertising_text_left {
  padding-right: 1em; }

@media (max-width: 767px) {
  .advertising_text_left {
    padding-right: 0; } }

.advertisingsection {
  margin-top: 1em;
  margin-bottom: 2em; }

.advertising_image {
  border: 1px solid #666; }

.advertising_image {
  width: 90%; }

/*! MAP */
#map {
  height: 100%;
  width: 100%;
  color: #000;
  font-weight: 400; }

#map a {
  font-weight: 700; }

/*! BLOG */
.blog {
  padding-top: 1em;
  padding-bottom: 3em; }

@media (max-width: 767px) {
  .blog {
    padding-top: 1em;
    padding-bottom: 3em; } }

@media (max-width: 576px) {
  .blog {
    padding-top: 1em;
    padding-bottom: 1em; } }

.blog_row {
  margin-top: 1em; }

@media (max-width: 992px) {
  .blog_row {
    margin-top: 0.5em; } }

.blog_filter {
  margin-bottom: 0.25em; }

.blog_filter ul {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0; }

.blog_filter li {
  display: inline-block;
  padding: 0;
  margin: 0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-left: 0.75em;
  margin-right: 0.75em;
  margin-bottom: 0.5em;
  cursor: pointer;
  font-weight: 700;
  line-height: 110%; }

.blog_filter li:hover {
  color: #000;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.blog_filter li.active {
  color: #000;
  border-bottom: 2px solid #000; }

.blog_item {
  padding-top: 2em;
  padding-bottom: 1em; }

@media (max-width: 767px) {
  .blog_item {
    padding-top: 1.5em; } }

.blog_photo {
  margin-bottom: 0.75em;
  width: 100%;
  padding-top: 70%;
  position: relative; }

@media (max-width: 767px) {
  .blog_photo {
    padding-top: 60%; } }

.blog_subtitle {
  font-weight: 700;
  font-size: 0.9em;
  line-height: 115%;
  letter-spacing: 0.02em;
  margin-bottom: 0.25em;
  color: #404040;
  padding-right: 0.5em; }

.blog_title,
.blog_title h1,
.blog_title h2,
.blog_title h3,
.blog_title h4,
.blog_title h5,
.blog_title h6 {
  color: #aa353e !important;
  line-height: 120%;
  margin-bottom: 0.25em;
  padding-right: 0.5em; }

@media (max-width: 992px) {
  .blog_title,
  .blog_title h1,
  .blog_title h2,
  .blog_title h3,
  .blog_title h4,
  .blog_title h5,
  .blog_title h6 {
    padding-right: 0.25em; } }

.blog_description {
  padding-right: 1em; }

@media (max-width: 992px) {
  .blog_description {
    padding-right: 0.5em; } }

.blog_button {
  margin-top: 2.5em; }

@media (max-width: 576px) {
  .blog_button {
    margin-top: 2em; } }

/*! BLANK PAGE */
.blank_text {
  padding-top: 3em;
  padding-bottom: 2em; }

@media (max-width: 576px) {
  .blank_text {
    padding-top: 2em;
    padding-bottom: 2em; } }

/*! Footer - Bottom */
.footer_bottom {
  font-size: 1em;
  background-color: #d9d9d9;
  padding-top: 1em;
  padding-bottom: 1em; }

.footer_bottom_left {
  padding: 0;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em; }

@media (max-width: 767px) {
  .footer_bottom_left {
    padding-left: 0;
    padding-right: 0;
    text-align: left; } }

.footer_bottom_right {
  padding: 0; }

@media (max-width: 767px) {
  .footer_bottom_right {
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 2em;
    text-align: center; } }

.footer_bottom_logo img {
  width: 7em;
  margin-bottom: 1em; }

@media (max-width: 1200px) {
  .footer_bottom_logo img {
    width: 6em; } }

@media (max-width: 576px) {
  .footer_bottom_logo img {
    width: 5em; } }

.footer_bottom_text {
  color: #000;
  padding-top: 1em;
  padding-bottom: 0;
  padding-left: 1em;
  padding-right: 1em; }

@media (max-width: 1600px) {
  .footer_bottom_text {
    padding-left: 0.5em; } }

@media (max-width: 1400px) {
  .footer_bottom_text {
    padding-left: 1em; } }

@media (max-width: 767px) {
  .footer_bottom_text {
    padding-right: 3em; } }

@media (max-width: 576px) {
  .footer_bottom_text {
    padding-right: 0em; } }

.footer_bottom_text_text {
  max-width: 27em;
  padding-right: 2em; }

@media (max-width: 992px) {
  .footer_bottom_text_text {
    padding-right: 1em; } }

@media (max-width: 767px) {
  .footer_bottom_text_text {
    max-width: 100%;
    padding-right: 0; } }

.footer_bottom_links {
  font-size: 1.1em;
  line-height: 115%;
  margin-top: 2em; }

@media (max-width: 1200px) {
  .footer_bottom_links {
    margin-top: 1.5em; } }

@media (max-width: 992px) {
  .footer_bottom_links {
    margin-top: 1.5em; } }

.footer_bottom_links ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.footer_bottom_links li {
  margin: 0;
  padding: 0;
  display: block;
  margin-bottom: 1.25em;
  padding-right: 0.5em; }

@media (max-width: 1200px) {
  .footer_bottom_links li {
    margin-bottom: 1em; } }

@media (max-width: 767px) {
  .footer_bottom_links li {
    padding-left: 0.5em;
    padding-right: 0.5em; } }

.footer_bottom_links a {
  text-decoration: underline;
  color: #404040; }

.footer_bottom_links a:hover {
  color: #000;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.footer_bottom_social {
  margin-top: 1.75em; }

.footer_bottom_social img {
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  margin-right: 1.5em;
  margin-bottom: 1em;
  opacity: 0.5; }

@media (max-width: 992px) {
  .footer_bottom_social img {
    margin-right: 1em; } }

@media (max-width: 900px) {
  .footer_bottom_social img {
    margin-right: 0.75em; } }

@media (max-width: 767px) {
  .footer_bottom_social img {
    margin-right: 2em; } }

@media (max-width: 576px) {
  .footer_bottom_social img {
    margin-right: 1.5em;
    opacity: 0.675; } }

@media (max-width: 480px) {
  .footer_bottom_social img {
    margin-right: 1.25em; } }

.footer_bottom_social img:hover {
  opacity: 1;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

/*! Footer - Copyright */
.footer {
  background: #ededed;
  padding-top: 2em;
  padding-bottom: 2em;
  font-size: 0.85em;
  margin-top: 1em; }

@media (max-width: 576px) {
  .footer {
    padding-top: 2em;
    padding-bottom: 2em; } }

.footer,
.footer a {
  color: #404040;
  font-weight: 700; }

.footer .active a {
  color: #404040;
  border-bottom: 2px solid #404040; }

.footer a:hover {
  color: #000;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.footer_left {
  text-align: left; }

@media (max-width: 576px) {
  .footer_left {
    text-align: center; } }

.footer_right {
  text-align: right; }

@media (max-width: 576px) {
  .footer_right {
    text-align: center;
    margin-bottom: 1em; } }

.footer_legal_right {
  margin-left: 4em; }

@media (max-width: 992px) {
  .footer_legal_right {
    margin-left: 3em; } }

@media (max-width: 767px) {
  .footer_legal_right {
    margin-left: 2.5em; } }

@media (max-width: 576px) {
  .footer_legal_right {
    margin-left: 2em; } }

.footer_legal_left a.active,
.footer_legal_right a.active {
  text-decoration: underline; }

/*! FORMS */
select,
input {
  background-color: #fff;
  -webkit-appearance: none;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border: 2px solid #000;
  color: #000 !important;
  margin-bottom: 1em; }

select:hover,
input:hover {
  background-color: #fafafa; }

select:focus,
input:focus {
  background-color: #fff; }

.form-floating > .form-control::-webkit-input-placeholder
.form-floating > .form-control::-moz-placeholder
.form-floating > .form-control::placeholder
.form-floating > .form-control:not(:-moz-placeholder-shown)
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown)
.form-floating > .form-control:-webkit-autofill
.form-floating > .form-select
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label,
.form-control::-webkit-input-placeholder,
.form-control::-moz-placeholder,
.form-control::placeholder,
.form-control:placeholder,
.form-control::placeholder,
.form-control::-webkit-input-placeholder,
.form-control:-moz-placeholder,
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder,
.form-control:placeholder-shown,
input:placeholder,
input::placeholder,
input::-webkit-input-placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
input::-ms-input-placeholder,
input:placeholder-shown,
:placeholder,
::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder,
:placeholder-shown {
  color: #000 !important;
  opacity: 1; }

.join-title {
  font-size: 1.1em; }

.join-popup b,
.join-popup strong {
  font-size: 1.1em; }

.join-popup input {
  padding-right: 2em;
  width: 100% !important;
  margin-top: 0.5em;
  margin-bottom: 1em; }

.join-popup button {
  margin-top: 1em; }

.join_bottomtext {
  margin-top: 1em; }

.join_left {
  padding-right: 2em; }

@media (max-width: 767px) {
  .join_left {
    padding-right: 0.8em;
    margin-bottom: 2em; } }

.join_right {
  border-left: 1px solid #666;
  padding-left: 2em; }

@media (max-width: 767px) {
  .join_right {
    border-left: 0;
    padding-left: 0.8em; } }

.join_right ul {
  margin-top: 1em; }

.join_right li {
  margin-bottom: 1em; }

/*! Buttons */
input[type="file"],
input[type="submit"],
button {
  color: #fff !important;
  background-color: #aa353e;
  font-size: 1em;
  font-weight: 700;
  line-height: 130%;
  border: 0;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 1.5em;
  padding-right: 1.5em; }

a button {
  color: #fff; }

button.active {
  background-color: #404040; }

input[type="file"]:hover,
input[type="submit"]:hover,
button:hover {
  background-color: #c74b55;
  color: #fff;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.button_white {
  color: #545454 !important;
  background-color: #fff;
  border: 2px solid #545454; }

.button_white:hover {
  color: #fff;
  background-color: #545454;
  border: 2px solid #545454;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

.button_whiteandblack {
  color: #000 !important;
  background-color: #fff;
  border: 2px solid #000; }

.button_whiteandblack:hover {
  color: #000;
  background-color: #f0cfd2;
  border: 2px solid #000;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

button.button_blue,
input[type="submit"].button_blue {
  color: #fff;
  background-color: #aa353e;
  border: 2px solid #aa353e; }

button.button_blue:hover,
input[type="submit"].button_blue:hover {
  color: #fff;
  background-color: #c74b55;
  border: 2px solid #c74b55;
  transition: .25s ease-out;
  -moz-transition: .25s ease-out;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out; }

/*! COLORS */
.background-color-yellow {
  background-color: #ffff00 !important; }

.color-primary {
  color: #aa353e !important; }

.background-color-primary {
  background-color: #aa353e !important; }

.background-color-primary a,
.background-color-primary b,
.background-color-primary strong,
.background-color-primary h1,
.background-color-primary h2,
.background-color-primary h3,
.background-color-primary h4,
.background-color-primary h5,
.background-color-primary h6,
.background-color-primary {
  color: #fff !important; }

.background-color-primary a:hover {
  color: #f0cfd2 !important; }

.background-color-primarylight {
  background-color: #f0cfd2 !important; }

.background-color-blue {
  background-color: #aa353e !important; }

.background-color-blue a,
.background-color-blue b,
.background-color-blue strong,
.background-color-blue h1,
.background-color-blue h2,
.background-color-blue h3,
.background-color-blue h4,
.background-color-blue h5,
.background-color-blue h6,
.background-color-blue {
  color: #fff !important; }

.background-color-blue a:hover {
  color: #f0cfd2 !important; }

.background-color-bluelight {
  background-color: #f0cfd2 !important; }

.background-color-white {
  background-color: #fff !important; }

.background-color-lightgrey {
  background-color: #ededed !important;
  color: #474747; }

.background-color-darkgrey {
  background-color: #d9d9d9 !important;
  color: #4d4d4d; }

/*! YouTube videos */
.embed-youtube,
.responsiveyoutube {
  display: inline-block;
  width: 100% !important;
  border: 0;
  margin: 0;
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden !important; }

.embed-youtube iframe,
.responsiveyoutube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important; }

/*! Mobile Only */
.hideonmobile {
  display: block; }

@media screen and (max-width: 767px) {
  .hideonmobile {
    display: none; } }

.showonmobile {
  display: none; }

@media screen and (max-width: 767px) {
  .showonmobile {
    display: block; } }

.hideonmedium {
  display: block; }

@media screen and (max-width: 992px) {
  .hideonmedium {
    display: none; } }

.showonmedium {
  display: none; }

@media screen and (max-width: 992px) {
  .showonmedium {
    display: block; } }

/*! Print */
@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari */
    color-adjust: exact !important;
    /* Firefox*/ }
  html, body {
    width: 1200px;
    font-size: 100%;
    color: #000; }
  html {
    margin: 0; }
  @page {
    size: 1200px; }
  div {
    break-inside: avoid;
    page-break-inside: avoid; }
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    clear: both; }
  div[class^="col-"], div[class*=" col-"] {
    float: left; } }

/*! Site Specific Print Styles */
@media print {
  .pricing_column {
    max-width: 33%;
    margin-left: 0;
    margin-right: 0; }
  .faq_answer {
    display: block !important; }
  .ttn_mission_photo_container {
    display: none; }
  .features_slideshow_text {
    position: relative;
    display: block;
    width: 75%;
    margin: 0;
    padding-left: 1em;
    padding-right: 1em; }
  .blog_item,
  .isotope_item {
    position: relative;
    display: block;
    max-width: 33%;
    padding-left: 1em;
    padding-right: 1em; }
  .home_why_item {
    position: relative;
    display: block;
    max-width: 50%;
    padding-left: 1em;
    padding-right: 1em; } }
