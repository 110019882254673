


/*! YouTube videos */

.embed-youtube,
.responsiveyoutube {
display:inline-block;
width:100% !important;
border:0;
margin:0;
padding:0;
margin-top:0.5em;
margin-bottom:0.5em;
position: relative;
padding-bottom: 56.25%;
height: 0;
overflow: hidden !important;
}

.embed-youtube iframe,
.responsiveyoutube iframe {
position: absolute;
top: 0;
left: 0;
width:100% !important;
height:100% !important;
border:0 !important;
margin:0 !important;
padding:0 !important;
overflow: hidden !important;
}


