

/*! FEATURES */



.features_slogan {
background-color:$color-background-darkgrey;
padding-top:2em;
padding-bottom:0.7em;
}
@media (max-width: 767px) {
.features_slogan {
padding-top:1.5em;
padding-bottom:0.325em;
}
}

.features_button {
margin-top:2em;
}

.features_slideshow {
padding-bottom:0em;
margin-bottom:1em;
}

.features_slideshow_photo {
padding:0;
}

.features_slideshow_text {
padding-top:2em;
padding-bottom:3em;
}
@media (max-width: 767px) {
.features_slideshow_text {
padding-top:1em;
padding-bottom:3em;
}
}

.features_slide_description_col {
z-index:100;
}

.features_slide_photo {
background-color:$white;
color:$white;
min-height:30em;
margin-bottom:0.625em;
}
@media (max-width: 1300px) {
.features_slide_photo {
min-height:28em;
}
}
@media (max-width: 1200px) {
.features_slide_photo {
min-height:26em;
}
}
@media (max-width: 1100px) {
.features_slide_photo {
min-height:24em;
}
}
@media (max-width: 992px) {
.features_slide_photo {
min-height:20em;
}
}
@media (max-width: 900px) {
.features_slide_photo {
min-height:16em;
}
}
@media (max-width: 767px) {
.features_slide_photo {
min-height:18em;
}
}
@media (max-width: 640px) {
.features_slide_photo {
min-height:30em;
}
}

.features_slide_description {
padding-top:2em !important;
padding-bottom:4em !important;
}
@media (max-width: 1200px) {
.features_slide_description {
padding-top:2em !important;
padding-bottom:3.5em !important;
}
}
@media (max-width: 900px) {
.features_slide_description {
padding-top:2em !important;
padding-bottom:3.25em !important;
}
}
@media (max-width: 767px) {
.features_slide_description {
padding-top:2em !important;
padding-bottom:3em !important;
}
}





