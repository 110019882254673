

/*! TTN */



/*! Mission */

.ttn_mission_container {
margin-top:0;
}
@media (max-width: 767px) {
.ttn_mission_container {
padding-top: 0em;
padding-bottom: 1em;
}
}

@media (max-width: 767px) {
.ttn_mission_photo_container {
--bs-gutter-x: 0;
}
}

.ttn_mission_photo_row {
--bs-gutter-x: 0;
}

.ttn_mission_photo_left {
margin-bottom:1em;
padding-right:0.5em;
padding-left: 0;
}
@media (max-width: 767px) {
.ttn_mission_photo_left {
padding-right:0em;
}
}

.ttn_mission_photo_left_photo {
width:100%;
padding-top: 80%;
position: relative;
}
@media (max-width: 767px) {
.ttn_mission_photo_left_photo {
padding-top: 40%;
}
}

.ttn_mission_photo_right {
margin-bottom:1em;
padding-left:0.5em;
padding-right: 0;
}
@media (max-width: 767px) {
.ttn_mission_photo_right {
padding-left:0em;
}
}

.ttn_mission_photo_right_photo {
width:100%;
padding-top: 80%;
position: relative;
}
@media (max-width: 767px) {
.ttn_mission_photo_right_photo {
padding-top: 40%;
}
}

.ttn_mission_photo_bottom {
padding-left:0;
padding-right: 0;
}

.ttn_mission_text {
margin-top:0;
}
@media (max-width: 767px) {
.ttn_mission_text {
margin-top:1em;
}
}

.ttn_mission_description {
padding-right:3em;
}
@media (max-width: 1200px) {
.ttn_mission_description {
padding-right:2em;
}
}
@media (max-width: 1100px) {
.ttn_mission_description {
padding-right:1em;
}
}
@media (max-width: 992px) {
.ttn_mission_description {
padding-right:0;
}
}
@media (max-width: 767px) {
.ttn_mission_description {
padding-right:0;
}
}

.ttn_mission_row {
padding-top:0;
}







/*! Team */

.team_row {
padding-left:4em;
padding-right:4em;
margin-top:2em;
}
@media (max-width: 767px) {
.team_row {
padding-left:3em;
padding-right:3em;
}
}
@media (max-width: 576px) {
.team_row {
padding-left:4em;
padding-right:4em;
}
}

.team_photo {
padding-left:3em;
padding-right:3em;
margin-bottom:1em;
}
@media (max-width: 992px) {
.team_photo {
padding-left:2em;
padding-right:2em;
}
}
@media (max-width: 767px) {
.team_photo {
padding-left:1em;
padding-right:1em;
margin-bottom:0.5em;
}
}
@media (max-width: 576px) {
.team_photo {
padding-left:3em;
padding-right:3em;
margin-bottom:0.5em;
}
}
@media (max-width: 480px) {
.team_photo {
padding-left:1em;
padding-right:1em;
margin-bottom:0.5em;
}
}

.team_photo_photo {
margin-left:auto;
margin-right:auto;
border-radius:30em;
}

.team_social {
margin-top:0.25em;
text-align:center;
}

.team_social img {
width:1.25em;
height:1.25em;
margin-left:0.5em;
margin-right:0.5em;
opacity:0.5;
}
@media (max-width: 767px) {
.team_social img {
margin-left:0.325em;
margin-right:0.325em;
}
}

.team_social img:hover {
opacity:1;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}





