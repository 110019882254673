

* {
text-rendering: optimizeLegibility;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
box-sizing: border-box;
}



/*! Body Font Sizes */

html,body {
font-family: $font-body;
font-weight: $fontweight-body;
font-style: normal;
/*! font-size: 16px; 
line-height: 140%; */
color:$color-bodytext;
background-color:$color-pagebackground;
margin:0;
padding:0;
}
html,body {
font-size: 1em;
}
@media screen and (max-width: 20000px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 6000px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 5000px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 4000px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 3500px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 3000px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 2600px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 2400px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 2200px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 2000px) {
html,body {
font-size: 1em;
}
}
@media screen and (max-width: 1800px) {
html,body {
font-size: 1em;
}
}

/*! ACTUAL SIZE */
@media screen and (max-width: 1600px) {
html,body {
font-size: 1em;
}
}
/*! 110% - 1280w */
@media screen and (max-width: 1400px) {
html,body {
font-size: 1em;
}
}
/*! 125% */
@media screen and (max-width: 1200px) {
html,body {
font-size: 1em;
}
}
/*! 150% - 1024w */
@media screen and (max-width: 1100px) {
html,body {
font-size: 1em;
}
}
/*! 175% */
@media screen and (max-width: 900px) {
html,body {
font-size: 1em;
}
}
/*! 200% */
@media screen and (max-width: 800px) {
html,body {
font-size: 1em;
}
}
/*! MOBILE */
@media screen and (max-width: 640px) {
html,body {
font-size: 1em;
}
}






/*! HTML */

.h1,h1,
.h2,h2,
.h3,h3,
.h4,h4,
.h5,h5,
.h6,h6 {
font-family:$font-headlines;
font-weight:$fontweight-headline;
color:$color-headline;
margin-top: 0;
margin-bottom: 0.5em;
font-size:2.5em;
line-height:110%;
letter-spacing:-0.02em;
}

.h1,h1 {
font-size:3em;
line-height:120%;
}
@media (max-width: 1200px) {
.h1,h1 {
font-size:2.75em;
}
}
@media (max-width: 992px) {
.h1,h1 {
font-size:2.5em;
}
}
@media (max-width: 900px) {
.h1,h1 {
font-size:2.25em;
}
}
@media (max-width: 767px) {
.h1,h1 {
font-size:2em;
}
}
@media (max-width: 576px) {
.h1,h1 {
font-size:2em;
}
}

.h2,h2 {
font-size:3em;
}
@media (max-width: 1200px) {
.h2,h2 {
font-size:2em;
}
}
@media (max-width: 900px) {
.h2,h2 {
font-size:1.875em;
}
}
@media (max-width: 767px) {
.h2,h2 {
font-size:1.75em;
}
}

.h3,h3 {
font-size:2.5em;
}
@media (max-width: 1200px) {
.h3,h3 {
font-size:2.25em;
}
}
@media (max-width: 900px) {
.h3,h3 {
font-size:2em;
}
}
@media (max-width: 767px) {
.h3,h3 {
font-size:1.75em;
}
}

.h4,h4 {
font-size:2em;
}
@media (max-width: 1200px) {
.h4,h4 {
font-size:1.75em;
}
}
@media (max-width: 900px) {
.h4,h4 {
font-size:1.5em;
}
}
@media (max-width: 767px) {
.h4,h4 {
font-size:1.5em;
}
}

.h5,h5,
.h6,h6 {
font-size:1.5em;
}
@media (max-width: 1300px) {
.h5,h5,
.h6,h6 {
font-size:1.25em;
}
}




a {
color:$color-link;
text-decoration: none;
}

a:hover {
color:$color-link-hover;
text-decoration: none;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

dl, ol, ul {
margin-bottom: 1em;
}

ul li {
margin-bottom: 0em;
}

ol ol {
list-style-type: lower-alpha;
}

b, strong {
font-weight: 700;
color:$color-bold;
}

img {
width:100%;
}





.clear {
clear:both;
}

@media screen and (max-width: 767px) {
.clear_mobileonly {
clear:both;
}
}




