


/*! Buttons */


input[type="file"],
input[type="submit"],
button {
color:$color-button-text !important;
background-color:$color-button;
font-size:1em;
font-weight:700;
line-height:130%;
border: 0;
padding-top:0.6em;
padding-bottom:0.6em;
padding-left:1.5em;
padding-right:1.5em;
}

a button {
color:$color-button-text;
}

button.active {
background-color:$color-topnavigation-link-active;
}

input[type="file"]:hover,
input[type="submit"]:hover,
button:hover {
background-color:$color-button-hover;
color:$color-button-text-hover;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.button_white {
color:$color-buttonwhite-text !important;
background-color:$color-buttonwhite;
border:2px solid $color-buttonwhite-text;
}

.button_white:hover {
color:$color-buttonwhite-text-hover;
background-color:$color-buttonwhite-hover;
border:2px solid $color-buttonwhite-hover;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

.button_whiteandblack {
color:$color-buttonwhiteandblack-text !important;
background-color:$color-buttonwhiteandblack;
border:2px solid $color-buttonwhiteandblack-text;
}

.button_whiteandblack:hover {
color:$color-buttonwhiteandblack-text;
background-color:$primary_light;
border:2px solid $color-buttonwhiteandblack-text;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}

button.button_blue,
input[type="submit"].button_blue {
color:$color-buttonprimary-text;
background-color:$color-buttonprimary;
border:2px solid $color-buttonprimary;
}

button.button_blue:hover,
input[type="submit"].button_blue:hover {
color:$color-buttonprimary-text-hover;
background-color:$color-buttonprimary-hover;
border:2px solid $color-buttonprimary-hover;
transition: .25s ease-out;
-moz-transition: .25s ease-out;
-webkit-transition: .25s ease-out;
-o-transition: .25s ease-out;
}


