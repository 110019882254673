

/*! COLORS */


.background-color-yellow {
background-color:$breakdownnow-yellow !important;
}

.color-primary {
color:$primary !important;
}

.background-color-primary {
background-color:$primary !important;
}

.background-color-primary a,
.background-color-primary b,
.background-color-primary strong,
.background-color-primary h1,
.background-color-primary h2,
.background-color-primary h3,
.background-color-primary h4,
.background-color-primary h5,
.background-color-primary h6,
.background-color-primary {
color:$white !important;
}

.background-color-primary a:hover {
color:$primary_light !important;
}

.background-color-primarylight {
background-color:$primary_light !important;
}

.background-color-blue {
background-color:$primary !important;
}

.background-color-blue a,
.background-color-blue b,
.background-color-blue strong,
.background-color-blue h1,
.background-color-blue h2,
.background-color-blue h3,
.background-color-blue h4,
.background-color-blue h5,
.background-color-blue h6,
.background-color-blue {
color:$white !important;
}

.background-color-blue a:hover {
color:$primary_light !important;
}

.background-color-bluelight {
background-color:$primary_light !important;
}

.background-color-white {
background-color:$white !important;
}

.background-color-lightgrey {
background-color:$color-background-lightgrey !important;
color:$color-background-lightgrey-text;
}

.background-color-darkgrey {
background-color:$color-background-darkgrey !important;
color:$color-bodytext;
}



