

/*! HOME */




/*! HOME - BENEFITS */


.home_benefits {
padding-top:1em;
padding-bottom:1em;
}

.home_benefits_row {
padding-top:1.5em;
padding-bottom:1.5em;
display: flex;
justify-content: center;
align-items: center;
}
@media (max-width: 900px) {
.home_benefits_row {
padding-top:1.5em;
padding-bottom:1.5em;
}
}
@media (max-width: 576px) {
.home_benefits_row {
padding-top:1.5em;
padding-bottom:1.5em;
}
}

.home_benefits_text {
text-align:left;
padding-top:2em;
padding-bottom:1em;
padding-left:0;
padding-right:2em;
}
@media (max-width: 1300px) {
.home_benefits_text {
padding-top:1em;
padding-bottom:0.5em;
}
}
@media (max-width: 576px) {
.home_benefits_text {
padding-top:0em;
padding-bottom:0em;
padding-left:0;
padding-right:0;
}
}

.home_benefits_text_right {
padding-left:2em;
padding-right:0;
}
@media (max-width: 576px) {
.home_benefits_text_right {
padding-left:0;
padding-right:0;
}
}

.home_benefits_photo {
margin:0;
padding:0;
}
@media (max-width: 576px) {
.home_benefits_photo {
margin-bottom:1em;
}
}

.home_benefits_photo_photo {
width:100%;
padding-top: 66%;
position: relative; 
}
@media (max-width: 900px) {
.home_benefits_photo_photo {
padding-top: 80%;
}
}
@media (max-width: 576px) {
.home_benefits_photo_photo {
padding-top: 60%;
}
}

.home_benefits_title h1,
.home_benefits_title h2,
.home_benefits_title h3,
.home_benefits_title h4,
.home_benefits_title h5,
.home_benefits_title h6 {
text-align:left;
color:$primary !important;
}

.home_benefits_button {
margin-top:2.5em;
}
@media (max-width: 1400px) {
.home_benefits_button {
margin-top:2em;
}
}
@media (max-width: 1300px) {
.home_benefits_button {
margin-top:1.5em;
}
}
@media (max-width: 992px) {
.home_benefits_button {
margin-top:1em;
}
}
@media (max-width: 576px) {
.home_benefits_button {
margin-top:1em;
}
}









/*! HOME - RED BOX */


.home_redbox {
background-color:$primary;
color:$white;
padding-top:3.5em;
padding-bottom:3em;
}
@media (max-width: 992px) {
.home_redbox {
padding-top:2.5em;
padding-bottom:2em;
}
}
@media (max-width: 576px) {
.home_redbox {
padding-top:1.5em;
padding-bottom:1em;
}
}

.home_redbox h1,
.home_redbox h2,
.home_redbox h3,
.home_redbox h4,
.home_redbox h5,
.home_redbox h6 {
color:$black !important;
font-size:2em;
line-height:115%;
}
@media (max-width: 1300px) {
.home_redbox h1,
.home_redbox h2,
.home_redbox h3,
.home_redbox h4,
.home_redbox h5,
.home_redbox h6 {
font-size:1.75em;
}
}
@media (max-width: 1200px) {
.home_redbox h1,
.home_redbox h2,
.home_redbox h3,
.home_redbox h4,
.home_redbox h5,
.home_redbox h6 {
font-size:1.5em;
}
}
@media (max-width: 992px) {
.home_redbox h1,
.home_redbox h2,
.home_redbox h3,
.home_redbox h4,
.home_redbox h5,
.home_redbox h6 {
font-size:1.25em;
}
}
@media (max-width: 576px) {
.home_redbox h1,
.home_redbox h2,
.home_redbox h3,
.home_redbox h4,
.home_redbox h5,
.home_redbox h6 {
font-size:1.75em;
}
}

.home_redbox_icon {
margin-bottom:2.5em;
}
@media (max-width: 1200px) {
.home_redbox_icon {
margin-bottom:2em;
}
}
@media (max-width: 992px) {
.home_redbox_icon {
margin-bottom:1em;
}
}
@media (max-width: 576px) {
.home_redbox_icon {
margin-bottom:1em;
margin-top:1em;
}
}

.home_redbox_icon,
.home_redbox_icon img {
width:auto;
height:3.25em;
}
@media (max-width: 1200px) {
.home_redbox_icon,
.home_redbox_icon img {
height:3em;
}
}
@media (max-width: 992px) {
.home_redbox_icon,
.home_redbox_icon img {
height:2.5em;
}
}
@media (max-width: 767px) {
.home_redbox_icon,
.home_redbox_icon img {
height:2.25em;
}
}
@media (max-width: 576px) {
.home_redbox_icon,
.home_redbox_icon img {
height:2.25em;
}
}

.home_redbox_title {
margin-bottom:0.5em;
}
@media (max-width: 992px) {
.home_redbox_title {
margin-bottom:0.25em;
}
}

.home_redbox_description {
font-size:0.9em;
padding-right:2em;
margin-bottom:1em;
}
@media (max-width: 767px) {
.home_redbox_description {
font-size:0.9em;
padding-right:1em;
}
}
@media (max-width: 576px) {
.home_redbox_description {
font-size:1em;
padding-right:0em;
margin-bottom:2em;
}
}





/*! HOME - WHY */


.home_why {
background-color:$color-background-lightgrey;
color:$color-background-lightgrey-text;
padding-top:5em;
padding-bottom:3em;
}
@media (max-width: 1300px) {
.home_why {
padding-top:4em;
padding-bottom:1.5em;
}
}
@media (max-width: 1100px) {
.home_why {
padding-top:3em;
padding-bottom:1.5em;
}
}
@media (max-width: 576px) {
.home_why {
padding-top:2em;
padding-bottom:1.5em;
}
}

.home_why_description {
text-align:center;
}

.home_why_item_row {
margin-top:4em;
}
@media (max-width: 1300px) {
.home_why_item_row {
margin-top:3em;
}
}

.home_why_item {
margin-bottom:3em;
}
@media (max-width: 767px) {
.home_why_item {
margin-bottom:2.5em;
}
}

.home_why_icon {
padding-left: 0;
}

.home_why_icon_circle {
width:10em;
height:10em;
border-radius:10em;
}
@media (max-width: 1300px) {
.home_why_icon_circle {
width:8em;
height:8em;
border-radius:8em;
}
}
@media (max-width: 992px) {
.home_why_icon_circle {
width:6em;
height:6em;
border-radius:6em;
}
}
@media (max-width: 767px) {
.home_why_icon_circle {
width:4em;
height:4em;
border-radius:4em;
}
}

.home_why_text {
text-align:left;
padding-left: 0;
padding-right: 2em;
padding-top:1em;
}
@media (max-width: 992px) {
.home_why_text {
text-align:left;
padding-left: 0;
padding-right: 1em;
}
}
@media (max-width: 767px) {
.home_why_text {
padding-left: 0;
padding-right: 0;
}
}

.home_why_text_text {
padding-left:1em;
padding-right:0.5em;
}
@media (max-width: 992px) {
.home_why_text_text {
padding-left:0.5em;
padding-right:0.5em;
}
}
@media (max-width: 767px) {
.home_why_text_text {
padding-left:0.5em;
padding-right:0em;
}
}

.home_why_text h1,
.home_why_text h2,
.home_why_text h3,
.home_why_text h4,
.home_why_text h5,
.home_why_text h6 {
text-align:left;
}

.home_why_text_link {
font-weight:700;
font-size:0.85em;
line-height:115%;
letter-spacing:0.02em;
margin-top:1em;
color:$color-blog-subtitle;
}
@media (max-width: 767px) {
.home_why_text_link {
margin-top:0.5em;
}
}

.home_why_title h2 {
color:$primary !important;
}








/*! HOME - SLOGAN */


.home_slogan {
background-color:$color-background-lightgrey;
padding-top:4em;
padding-bottom:4em;
}
@media (max-width: 767px) {
.home_slogan {
padding-top:2em;
padding-bottom:1.5em;
}
}

.home_slogan h3 {
line-height:130%;
}

.home_slogan h1,
.home_slogan h2,
.home_slogan h3,
.home_slogan h4,
.home_slogan h5,
.home_slogan h6 {
color:$primary !important;
font-size:2.5em;
}
@media (max-width: 1300px) {
.home_slogan h1,
.home_slogan h2,
.home_slogan h3,
.home_slogan h4,
.home_slogan h5,
.home_slogan h6 {
font-size:2.125em;
}
}
@media (max-width: 1200px) {
.home_slogan h1,
.home_slogan h2,
.home_slogan h3,
.home_slogan h4,
.home_slogan h5,
.home_slogan h6 {
font-size:2em;
}
}
@media (max-width: 992px) {
.home_slogan h1,
.home_slogan h2,
.home_slogan h3,
.home_slogan h4,
.home_slogan h5,
.home_slogan h6 {
font-size:1.75em;
}
}
@media (max-width: 767px) {
.home_slogan h1,
.home_slogan h2,
.home_slogan h3,
.home_slogan h4,
.home_slogan h5,
.home_slogan h6 {
font-size:1.5em;
}
}







/*! HOME - CLIENT LOGOS */


.home_logos {
padding-top:2em;
padding-bottom:2em;
}
@media (max-width: 576px) {
.home_logos {
padding-top:1em;
padding-bottom:2em;
}
}

.clientlogos_slide {
padding-top:1em;
padding-bottom:1em;
padding-left:1em;
padding-right:1em;
}

@media (max-width: 576px) {
.clientlogos_slide {
padding-top:2em;
padding-bottom:2em;
padding-left:2em;
padding-right:2em;
}
}

.home_logos .slick-dots {
bottom: -1em;
}
@media (max-width: 576px) {
.home_logos .slick-dots {
bottom: 0em;
}
}





